import React, { FC } from 'react'

import { DataPointType } from 'store'

type DataPointLabelProps = {
	dataPoint: DataPointType
	field?: 'unit' | 'label' | 'shortLabel'
}

export const GetDataPointString = (dataPoint:DataPointType, field = 'label', value?:any):string => {
  value = (value === undefined || value === null) ? "" : value
  switch (field) {
  case 'unit':
    switch (dataPoint) {
    case DataPointType.O2:
      return `${value}%`
    case DataPointType.Pulse:
      return `${value} bpm`
    case DataPointType.Sleep:
      return `${value} hrs`
    case DataPointType.StepCounter:
      return `${value} steps`
    case DataPointType.Weight:
      return `${value} lbs`
    case DataPointType.Systolic:
    case DataPointType.Diastolic:
    case DataPointType.BloodPressure:
      return `${value} mmHg`
    case DataPointType.Ecg:
      return `${(value ? (value / 1000) : 0 ).toFixed(2)}mV`
    default:
      return `${value}`
    }
  case 'shortLabel':
  case 'label':
	const short = field === 'shortLabel'
    switch (dataPoint) {
    case DataPointType.O2:
      return "Oxygen"
    case DataPointType.Pulse:
      return "Heart Rate"
    case DataPointType.Sleep:
      return "Sleep"
    case DataPointType.StepCounter:
      return "Activity"
    case DataPointType.Weight:
      return "Weight"
    case DataPointType.Systolic:
			return "Systolic"
    case DataPointType.Diastolic:
			return "Diastolic"
    case DataPointType.BloodPressure: 
      return short ? "B P" : "Blood Pressure"
    case DataPointType.Ecg:
      return "ECG"
    default:
      return ""
    }
  }
  return ""
}

const DataPointLabel: FC<DataPointLabelProps> = ({ dataPoint, field = 'label' }) => {
	return <>{GetDataPointString(dataPoint, field)}</>
}

export default DataPointLabel
