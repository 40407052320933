import React, { FC } from 'react'

import { Box } from '@material-ui/core'

import PageBanner from 'components/PageBanner'

const DevicesView: FC = () => {

	return (
		<Box flex={1}>
			<PageBanner title="Transmission Log" />
			Hello DevicesView
		</Box>
	)
}

export default DevicesView
