import React, { FC } from 'react'

import { useTheme } from '@material-ui/core'

const GraphPointMarker: FC<any> = (props) => {
	const muiTheme = useTheme()
	const { x, y, large, color } = props;

	let size = large ? { w: 18, h: 18 } : { w: 8, h: 8 }
	
	return (
		<g>
			<svg x={x - size.w / 2} y={y - size.h / 2} width={size.w} height={size.h} viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
				  <circle cx="2" cy="2" r="2" fill={color || muiTheme.palette.dataPlot.line} />
			</svg>
		</g>
	);
}

export default GraphPointMarker