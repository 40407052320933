import React, { FC } from 'react'

import { Box, makeStyles, useTheme } from '@material-ui/core'
import { DataPointType } from 'store'
import DataPointIcon, { DataPointIconProps } from './DataPointIcon'

const useStyles = makeStyles((theme) => ({
	root: {

	},
	inactiveIcon: {
		color: theme.palette.alertInactive,
	},
	activeIcon: {
		color: theme.palette.alertNominal,
	},
	alertIcon: {
		color: theme.palette.alertRed,
	},
}))

const DataPointIconList: FC<{ thresholdedDataPoints: Array<DataPointType>, alertDataPoints: Array<DataPointType> }> = ({ thresholdedDataPoints, alertDataPoints }) => {
	const styles = useStyles()
	const theme = useTheme()

	const getProps = (dpt: DataPointType): Partial<DataPointIconProps> => {
		let valsToCheck = [dpt]
		if (dpt === DataPointType.BloodPressure) {
			valsToCheck = [DataPointType.BloodPressure, DataPointType.Systolic, DataPointType.Diastolic]
		}
		if (!!valsToCheck.find(v => alertDataPoints.indexOf(v) > -1)) {
			return { alerted: true }
		} else if (!!valsToCheck.find(v => thresholdedDataPoints.indexOf(v) > -1)) {
			return { thresholded: true }
		} else {
			return {}
		}
	}
	
	return (
		<Box className={styles.root} display="flex" justifyContent="center" alignItems="center" style={{ color: theme.palette.alertInactive }}>
			<DataPointIcon dataPoint={DataPointType.Pulse} {...getProps(DataPointType.Pulse)} />
			<DataPointIcon dataPoint={DataPointType.StepCounter} {...getProps(DataPointType.StepCounter)} />
			<DataPointIcon dataPoint={DataPointType.Sleep} {...getProps(DataPointType.Sleep)} />
			<DataPointIcon dataPoint={DataPointType.O2} {...getProps(DataPointType.O2)} />
			<DataPointIcon dataPoint={DataPointType.Weight} {...getProps(DataPointType.Weight)} />
			<DataPointIcon dataPoint={DataPointType.BloodPressure} {...getProps(DataPointType.BloodPressure)} />
			<DataPointIcon dataPoint={DataPointType.Ecg} {...getProps(DataPointType.Ecg)} />
		</Box>
	)
}

export default DataPointIconList
