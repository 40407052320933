import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, TextFieldProps, Typography, CircularProgress } from '@material-ui/core';

import { DataPointType, useSetPatientThreshold } from 'store';
import { DataPointLabel } from 'components';
import { isFinite } from 'underscore';


export type ThresholdEditorProps = {
	open: boolean;
	dataPointType: DataPointType
	patientId: string
	onClose: () => void;
	currentMin?: number;
	currentMax?: number;
}

const ThresholdEditor: FC<ThresholdEditorProps> = ({ patientId, onClose, open, dataPointType, currentMin, currentMax }) => {
	const [min, setMin] = useState<number | undefined>(undefined)
	const [max, setMax] = useState<number | undefined>(undefined)
	const [error, setError] = useState<string | undefined>(undefined)
	const [saveFn, saveStatus] = useSetPatientThreshold(patientId, dataPointType)
	const { loading, called, error: saveError } = saveStatus

	useEffect(() => {
		if (!open) {
			return
		}
		if (!loading && called) {
			if (saveError) {
				setError("Failed to save!")
			} else {
				onClose()
			}
		}
	}, [onClose, open, loading, called, saveError])

	useEffect(() => {
		setError(undefined)
		setMin(currentMin)
		setMax(currentMax)
	}, [patientId, dataPointType, currentMin, currentMax])


	let tfProps: TextFieldProps = {
		type: "number",
		fullWidth: true,
		InputLabelProps: {
			shrink: true,
		},
		variant: "filled"
	}

	type ChangeHandlerFn = (evt: ChangeEvent<{ value: string }>) => void

	const onChange = (field: "min" | "max"): ChangeHandlerFn => {
		return (evt): void => {
			if (field === "min") {
				setMin(parseInt(evt.target.value, 10))
			} else {
				setMax(parseInt(evt.target.value, 10))
			}
		}
	}

	const onSave = (): void => {
		if (isFinite(min) && isFinite(max) && (min || 0) >= (max || 0)) {
			setError("Error, min must be less than max")
			return
		}
		setError(undefined)
		saveFn(min, max)
	}

	return (
		<Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
			<DialogTitle>Edit <DataPointLabel dataPoint={dataPointType} /> Threshold</DialogTitle>
			<DialogContent>
				<Box my={1}>
					<TextField label="Threshold Max" value={max || ""} onChange={onChange("max")} {...tfProps} />
				</Box>
				<Box my={1}>
					<TextField label="Threshold Min" value={min || ""} onChange={onChange("min")} {...tfProps} />
				</Box>
				<Box>
					<Typography color="error">{error}</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				{loading && <CircularProgress />}
				<Button disabled={loading} variant="contained" onClick={onClose}>Cancel</Button>
				<Button disabled={loading} variant="contained" onClick={onSave} color="primary">Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ThresholdEditor