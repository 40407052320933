import { format as dateFnsFormat } from 'date-fns'

export type FormatOptions = {
	locale?: Locale
	weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
	firstWeekContainsDate?: number
	useAdditionalWeekYearTokens?: boolean
	useAdditionalDayOfYearTokens?: boolean
}

export const format = (date: Date | number, formatStr: string, options?: FormatOptions): string => {
	try {
		return dateFnsFormat(date, formatStr, options)
	} catch (err) {
		console.warn(err)
	}
	return ''
}