import React, { FC, useState } from 'react'

import { Box, Typography } from '@material-ui/core'

import { IPatientInfo, DataPointType, PatientInterval } from 'store'
import DataPointCard, { DoubleDataPointCard } from './DataPointCard'
import SingleDataPointGraphModal, { GraphStyle } from './SingleDataPointGraphModal'
import EcgCard from './EcgCard'

const PatientDataView: FC<{ patient: IPatientInfo, selectedInterval?: PatientInterval }> = ({ patient, selectedInterval }) => {
	const [showFullHistoryModal, setShowFullHistoryModal] = useState<DataPointType | undefined>(undefined)

	if (!selectedInterval) {
		return (
			<Box flex={1} position="relative" overflow="auto" p={0.5}>
				<Typography color="error">No Interval Selected</Typography>
			</Box>
		)
	}
	let dpcProps = {
		patient,
		selectedInterval,
		onShowFullHistory: (dataPointType: DataPointType) => {
			setShowFullHistoryModal(dataPointType)
		}
	}
	
	const hasBpSystolic = !!selectedInterval.dataPointMetrics.find(dpm => dpm.dataPointType === DataPointType.Systolic)
	const hasBpDiastolic = !!selectedInterval.dataPointMetrics.find(dpm => dpm.dataPointType === DataPointType.Diastolic)
	
	const hasOnlyHalfBp = hasBpSystolic !== hasBpDiastolic
	const isBpGraph = showFullHistoryModal === DataPointType.Systolic || showFullHistoryModal === DataPointType.Diastolic || showFullHistoryModal === DataPointType.BloodPressure

	const graphStyle: GraphStyle = isBpGraph ? "interpolation" : "line"

	return (
		<Box flex={1} position="relative" overflow="auto" p={0.5}>

			{showFullHistoryModal && <SingleDataPointGraphModal graphStyle={graphStyle} endDate={selectedInterval.intervalEnd} intervalId={selectedInterval.id} open={!!showFullHistoryModal} dataPointType={showFullHistoryModal} secondaryDataPoint={showFullHistoryModal === DataPointType.Systolic ? DataPointType.Diastolic : undefined} patient={patient} onClose={() => setShowFullHistoryModal(undefined)} />}

			<Box display="flex">
				<Box width="50%">
					<DataPointCard {...dpcProps} dataPoint={DataPointType.Pulse} />
				</Box>
				<Box width="50%">
					<DataPointCard {...dpcProps} dataPoint={DataPointType.StepCounter} />
				</Box>
			</Box>
			<Box display="flex">
				<Box width="50%">
					<DataPointCard {...dpcProps} dataPoint={DataPointType.O2} />
				</Box>
				<Box width="50%">
					<DataPointCard {...dpcProps} dataPoint={DataPointType.Sleep} />
				</Box>
			</Box>
			<Box display="flex">
				<Box width="50%">
					{hasOnlyHalfBp && <DataPointCard {...dpcProps} dataPoint={hasBpSystolic ?  DataPointType.Systolic : DataPointType.Diastolic} />}
					{!hasOnlyHalfBp && <DoubleDataPointCard {...dpcProps} dataPoint={DataPointType.Systolic} secondaryDataPoint={DataPointType.Diastolic} />}
				</Box>
				<Box width="50%">
					<DataPointCard {...dpcProps} dataPoint={DataPointType.Weight} />
				</Box>
			</Box>
			<Box display="flex">
				<Box width="100%">
					<EcgCard {...dpcProps} />
				</Box>
			</Box>
		</Box>
	)
}

export default PatientDataView