export const getClassificationType = (classification: number): string => {
	switch (classification) {
		case 1:
			return "Sinus Rhythm"
		case 2:
			return "Atrial Fibrillation"
		case 3:
			return "Inconclusive Low Heart Rate"
		case 4:
			return "Inconclusive High Heart Rate"
		case 5:
			return "Inconclusive Poor Reading"
		case 6:
			return "Inconclusive Other"
		default:
			return "Unknown"
	}
}