import React, { FC, forwardRef, useMemo } from 'react'

import { makeStyles, Tooltip, useTheme } from '@material-ui/core'
import AirlineSeatIndividualSuiteOutlinedIcon from '@material-ui/icons/AirlineSeatIndividualSuiteOutlined'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import FavoriteIcon from '@material-ui/icons/Favorite'
import OpacityIcon from '@material-ui/icons/Opacity'
import Brightness5Icon from '@material-ui/icons/Brightness5'

import { DataPointType } from 'store'
import { GetDataPointString } from './DataPointLabel'


const HeartRateIcon = forwardRef<SVGSVGElement, { fill: string, stroke: string }>((props, ref) => {
	const { fill, stroke } = props
	return (
		<svg ref={ref} width="21" height="21" viewBox="0 -2 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.5 20.8125L8.9775 19.3738C3.57 14.2839 0 10.9269 0 6.80705C0 3.4501 2.541 0.8125 5.775 0.8125C7.602 0.8125 9.3555 1.69533 10.5 3.09043C11.6445 1.69533 13.398 0.8125 15.225 0.8125C18.459 0.8125 21 3.4501 21 6.80705C21 10.9269 17.43 14.2839 12.0225 19.3847L10.5 20.8125Z" fill={fill} />
			<path d="M0.538574 11.124H5.73621L6.71609 13.2655L8.46283 5.67725L10.4226 15.407L12.638 9.02911L14.3421 11.124H19.9232" stroke={stroke} />
		</svg>
	)
})

const WeightIcon = forwardRef<SVGSVGElement, { fill: string, stroke: string }>((props, ref) => {
	const { fill, stroke } = props
	return (
		<svg version="1.0" ref={ref}
			width="24" height="24" viewBox="0 -40 512.000000 512.000000"
			preserveAspectRatio="xMidYMid meet">

			<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
				fill={fill} stroke={stroke}>
				<path d="M2446 4250 c-239 -43 -442 -194 -546 -405 -37 -75 -70 -177 -70 -217
0 -16 -24 -17 -312 -20 l-313 -3 -63 -34 c-95 -51 -157 -131 -183 -235 -10
-40 3 -183 96 -1103 74 -728 113 -1079 126 -1125 33 -116 138 -209 267 -237
75 -16 2149 -16 2224 0 29 6 73 22 98 34 90 45 173 165 185 266 3 24 53 508
111 1074 104 1018 105 1031 90 1094 -27 110 -86 184 -190 239 l-51 27 -309 5
-308 5 -19 75 c-39 154 -134 306 -254 403 -69 56 -193 119 -280 141 -74 20
-233 28 -299 16z m246 -199 c106 -27 167 -61 248 -138 224 -211 229 -568 11
-783 -71 -70 -162 -124 -245 -146 -71 -19 -229 -18 -297 2 -273 81 -437 348
-389 632 18 108 69 202 154 287 82 81 145 118 248 145 94 24 176 25 270 1z
m-849 -709 c59 -262 281 -484 549 -547 92 -22 258 -19 352 5 262 67 475 282
536 542 l17 73 285 0 285 0 36 -28 c47 -36 67 -75 67 -130 0 -24 -47 -504
-105 -1066 -72 -701 -110 -1031 -120 -1051 -8 -16 -29 -39 -47 -52 l-33 -23
-1105 0 -1105 0 -33 23 c-18 13 -39 36 -47 52 -10 20 -48 347 -120 1051 -58
563 -105 1042 -105 1066 0 68 29 113 95 147 25 13 75 16 305 16 l275 0 18 -78z"/>
				<path d="M2507 3879 c-36 -21 -47 -71 -47 -213 0 -134 0 -136 -25 -152 -34
-22 -72 -89 -80 -139 -25 -156 145 -283 290 -216 137 63 166 230 60 337 l-44
43 -3 151 -3 152 -28 24 c-30 26 -87 32 -120 13z"/>
				<path d="M3226 2719 c-68 -16 -155 -70 -209 -129 -102 -110 -147 -232 -167
-452 -15 -169 -35 -283 -72 -413 -42 -145 -43 -225 -4 -312 52 -115 150 -183
266 -183 77 0 129 21 191 77 61 55 96 115 130 228 37 119 56 157 162 335 103
172 127 236 134 362 10 175 -28 289 -132 394 -43 43 -74 65 -110 77 -64 22
-140 29 -189 16z"/>
				<path d="M1717 2706 c-89 -33 -163 -104 -208 -200 -40 -85 -52 -155 -47 -271
7 -131 30 -198 128 -360 102 -171 131 -230 169 -350 51 -160 119 -243 231
-286 74 -28 135 -25 208 11 95 48 148 120 172 234 13 66 10 89 -30 236 -36
127 -56 244 -70 410 -6 69 -20 159 -31 200 -43 157 -137 284 -261 350 -54 29
-75 34 -143 37 -52 2 -93 -2 -118 -11z"/>
			</g>
		</svg>

	)
})

const useStyles = makeStyles((theme) => ({
	root: {

	},
	inactiveIcon: {
		color: theme.palette.alertInactive,
	},
	activeIcon: {
		color: theme.palette.alertNominal,
	},
	alertIcon: {
		color: theme.palette.alertRed,
	},
}))


export type DataPointIconProps = {
	dataPoint: DataPointType
	alerted?: boolean
	thresholded?: boolean
}
export const DataPointIcon: FC<DataPointIconProps> = ({ dataPoint, alerted, thresholded }) => {
	const styles = useStyles()
	const theme = useTheme()
	const icon = useMemo(() => {
		const baseProps = {
			fontSize: 'inherit' as const,
			color: 'inherit' as const,
		}

		const getClass = () => {
			if (alerted) {
				return styles.alertIcon
			} else if (thresholded) {
				return styles.activeIcon
			} else {
				return styles.inactiveIcon
			}
		}

		switch (dataPoint) {
			case DataPointType.Pulse:
				return <FavoriteIcon {...baseProps} className={getClass()} />
			case DataPointType.StepCounter:
				return <DirectionsRunIcon {...baseProps} className={getClass()} />
			case DataPointType.Sleep:
				return <AirlineSeatIndividualSuiteOutlinedIcon {...baseProps} className={getClass()} />
			case DataPointType.O2:
				return <OpacityIcon {...baseProps} className={getClass()} />
			case DataPointType.Weight:
			if (alerted) {
					return <span><WeightIcon fill={theme.palette.alertRed} stroke={"#fff"} /></span>
				} else if (thresholded) {
					return <span><WeightIcon fill={theme.palette.alertNominal} stroke={theme.palette.alertInactive} /></span>
				} else {
					return <span><WeightIcon fill={theme.palette.alertInactive} stroke={theme.palette.alertHeartStroke} /></span>
				}
			case DataPointType.BloodPressure:
			case DataPointType.Systolic:
			case DataPointType.Diastolic:
				return <Brightness5Icon {...baseProps} className={getClass()} />
			case DataPointType.Ecg:
				if (alerted) {
					return <span><HeartRateIcon fill={theme.palette.alertRed} stroke={"#fff"} /></span>
				} else if (thresholded) {
					return <span><HeartRateIcon fill={theme.palette.alertNominal} stroke={theme.palette.alertInactive} /></span>
				} else {
					return <span><HeartRateIcon fill={theme.palette.alertInactive} stroke={theme.palette.alertHeartStroke} /></span>
				}
			default:
				return <></>
		}
	}, [styles, theme, dataPoint, alerted, thresholded])

	return (
		<Tooltip title={GetDataPointString(dataPoint, "label") || "FOOOO"}>
			{icon}
		</Tooltip>
	)

}


export default DataPointIcon
