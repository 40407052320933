import React, { FC, MouseEvent, useEffect, useState } from 'react'

import { endOfMonth } from 'date-fns'
import { Box, Typography, makeStyles, Button, CircularProgress } from '@material-ui/core'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { useHistory } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Alert as AlertInfo } from '@material-ui/lab'

import { useGetReadyForSignoffList, IBillingListItem, useSignoffInterval, useGetPracticeInfo } from 'store'
import { ErrorBox, SnackbarError } from 'components'
import ColHeader from '../ColHeader'

const NoItems: FC = () => {
  const p2 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <Box {...p2}>
      <AlertInfo severity="info">
        There are currently no patients ready to sign
      </AlertInfo>
    </Box>
  )
}

const useCardStyles = makeStyles((theme) => ({
	root: {
		cursor: "pointer",
		position: "relative",
		height: "12rem",
		border: `solid 0.1rem ${theme.palette.borderColor}`,
	},
	leftBorderGreen: {
		background: theme.palette.alertGreen
	},
	leftBorderRed: {
		background: theme.palette.alertRed
	},
	smallText: {
		fontSize: "1.8rem",
		lineHeight: "2rem",
	},
	largeText: {
		fontSize: "4.8rem",
		lineHeight: "4.8rem"
	},
	approveButton: {
		width: "10rem",
		height: "4rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		right: "0.5rem",
		top: "0.75rem"
	}

}))

const useTransitionStyles = makeStyles(() => ({
	exit: {
		opacity: 1,
		position: "relative",
		overflow: "hidden",
		height: "13rem",
	},
	exitActive: {
		opacity: 0,
		height: 0,
		position: "relative",
		overflow: "hidden",
		transition: "all 300ms ease-in"
	},
	exitDone: {
		opacity: 0,
		height: 0,
		position: "relative",
		overflow: "hidden",
	}
}))

const SignCard: FC<{ data: IBillingListItem, customInterval:boolean }> = ({ data, customInterval }) => {
	const transitionClassNames = useTransitionStyles()
	const [approved, setApproved] = useState(false)
	const styles = useCardStyles()
	const history = useHistory()
	const [doSignoff, signoffStatus] = useSignoffInterval(data.id)
	const signedOff = !!data.signoff.find(s => !!s.approvedOn) || (!signoffStatus.error && !signoffStatus.loading && signoffStatus.called)
	useEffect(()=> {
		setApproved(signedOff)
	}, [signedOff])
	
	const onDoSignoff = (evt: MouseEvent<HTMLButtonElement>) => {
		evt.stopPropagation()
		doSignoff({ approved: true })
	}
	const canApprove = !!data.reportNote && !!data.daysOfData
	let borderClass = data.hadAlert ? styles.leftBorderRed : styles.leftBorderGreen
	const onClickCard = () => {
		history.push(`/patients/${data.patient.id}/${data.id}`)
	}
	return (
		<CSSTransition in={!approved} key={data.id} timeout={400} classNames={transitionClassNames} unmountOnExit>
			<Box onClick={onClickCard} mb={2} display="flex" className={styles.root}>
				<SnackbarError error={signoffStatus.error} message="Failed to approve" />
				<Box height="100%" width="2rem" className={borderClass} />
				<Box flex={1} display="flex" flexDirection="column" pl={1} pt={0.5} pb={1.2}>
					<Box>
						<Typography variant="h6" color="primary" component="span">
							{data.patient.firstName} {data.patient.lastName}
						</Typography>
						<Box className={styles.approveButton}>
							{signoffStatus.loading && <CircularProgress size={10} />}
							{!signoffStatus.loading && <Button disabled={!canApprove} color="primary" onClick={onDoSignoff}>Approve</Button>}
						</Box>
					</Box>
					<Box display="flex" flex={1} pb={1.6}>
						<Box borderRight={1} flex={1} textAlign="center">
							<div className={styles.smallText}>Data Gathered:</div>
							<div className={styles.largeText}>
								{data.daysOfData} <span className={styles.smallText}>DAYS</span>
							</div>
						</Box>
						<Box flex={2} px={1} textAlign="center">
							<div className={styles.smallText}>End Date:</div>
							<div className={styles.largeText}>
								{customInterval ? <FormattedDate value={data.intervalEnd} /> : <FormattedDate value={endOfMonth(data.intervalEnd - 86400000)} />}
							</div>
						</Box>
					</Box>
				</Box>
			</Box>
		</CSSTransition>
	)
}


const ReadyToSignList: FC = () => {
	const { loading, error, results } = useGetReadyForSignoffList()
	const practiceInfo = useGetPracticeInfo()
	
	let items = practiceInfo?.practice && results?.filter(r => !r.signoff.find(s => !!s.approvedOn))?.map(r => <SignCard key={r.id} data={r} customInterval={!!practiceInfo.practice?.customInterval}/>)

	return (
		<>
			<ColHeader title="Ready to Sign" loading={loading} />
			<Box flex={1} overflow="auto" display="flex" flexDirection="column">
				<Box display="flex" my={3} height={"4rem"}>
					<Box flex={1} />
					{!loading && !!items && <Box alignItems="flex-end" display="flex" ml={1}><Typography variant="h6"><FormattedNumber value={items.length} /></Typography></Box>}
				</Box>
				<Box flex={1} position="relative" overflow="hidden" flexDirection="column" display="flex" justifyContent="center" alignItems="center">
					{!!error && <ErrorBox my={2} message="Failed to load alerts" />}
					{!error && !loading && !results?.length && <NoItems />}
					<Box position="absolute" width="100%" height="100%" overflow="auto">
						<TransitionGroup>
							{items}
						</TransitionGroup>
					</Box>
				</Box>
				
			</Box>
		</>
	)
}

export default ReadyToSignList
