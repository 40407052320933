import React, { FC, useEffect, useState, useRef, MutableRefObject } from 'react'

import { isFinite, sortBy } from 'underscore'
import { VictoryTheme, VictoryAxis, VictoryChart, VictoryLine, VictoryLabel, VictoryChartProps, VictoryLabelProps, VictoryLineProps, VictoryZoomContainer, VictoryScatterProps, VictoryScatter } from 'victory'
import { useTheme } from '@material-ui/core'

import { PatientGraphData, DateTime } from 'store'
import { GraphAlertMarker, GraphPointMarker } from 'components'
import { GraphStyle } from './SingleDataPointGraphModal'

const useDimensions = (): [MutableRefObject<HTMLDivElement>, { width?: number, height?: number }] => {
	const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
	const [dimensions, setDimensions] = useState<{ width?: number, height?: number }>({
		width: undefined,
		height: undefined,
	})

	useEffect(() => {
		let handleResize = () => {
			if (ref.current) {
				setDimensions(ref.current.getBoundingClientRect().toJSON())
			}
		}

		window.addEventListener("resize", handleResize);
		handleResize()
		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, [ref])

	return [ref, dimensions]
}


export type DataPointGraphProps = {
	endDate: DateTime
	graphStyle: GraphStyle
	graphData: PatientGraphData
	xRange: number // in MS
	yRange?: [number, number]
	thresholdMin?: number
	thresholdMax?: number
	lineColor: string,
	markerPoints: Array<{
		time: DateTime,
		value: number
	}>
}

const DataPointGraph: FC<DataPointGraphProps> = ({ graphStyle, endDate, markerPoints, yRange, lineColor, graphData, xRange, thresholdMin = -Infinity, thresholdMax = Infinity }) => {
	const { dataPoints = [], thresholds = [] } = graphData || {}
	const [ref, dimensions] = useDimensions()
	const muiTheme = useTheme()

	const thresholdRed = muiTheme.palette.dataPlot.thresholdStroke
	let thresholdStyle = {
		data: { stroke: thresholdRed, strokeWidth: 1.0 },
	}

	let lineStyle = {
		data: { stroke: lineColor, strokeWidth: 1.25 },
	}

	let theme = {
		...VictoryTheme.material,
		axis: {
			...VictoryTheme.material.axis,
			"style": {
				...VictoryTheme.material.axis?.style,
				axis: {
					stroke: "transparent"
				},
				ticks: {
					stroke: "transparent"
				},
				grid: {
					...VictoryTheme.material.axis?.style?.grid,
					"stroke": muiTheme.palette.dataPlot.gridLineStroke,
					"strokeWidth": 1,
					"strokeDasharray": "1, 1",
				},
			}
		}
	}

	let now = Math.min(endDate, Date.now())

	let currThreshold = sortBy(thresholds, "createdOn").reverse()[0]
	let currThresholdMin = currThreshold?.min
	let currThresholdMax = currThreshold?.max

	let minPoint = now - xRange
	let maxPoint = now

	let chartProps: VictoryChartProps = {
		theme,
		domain: { x: [minPoint, maxPoint], y: yRange ? yRange : undefined },
		domainPadding: { y: 20 },
		width: dimensions.width || 220,
		height: dimensions.height || 60,
		padding: { top: 5, bottom: 5, right: 5, left: 45 },
		containerComponent: <VictoryZoomContainer responsive={true} allowPan={false} allowZoom={false} zoomDimension="x" />
	}

	const TickLabel: FC<VictoryLabelProps> = (props) => {
		if (props.text === `${currThresholdMin}` || props.text === `${currThresholdMax}`) {
			return <VictoryLabel {...props} style={[{ fill: thresholdRed }]} />
		}
		return <VictoryLabel {...props} />
	}

	let plotLines
	if (graphStyle === "line") {
		let lProps: VictoryLineProps = {
			style: lineStyle,
			data: dataPoints.filter(dp => dp.value !== undefined),
			x: "time",
			y: "value",
		}
		plotLines = <VictoryLine  {...lProps} />

	} else if (graphStyle === "plot" || graphStyle === "interpolation"){
		let sProps: VictoryScatterProps = {
			data: dataPoints.filter(dp => dp.value !== undefined),
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={lineStyle.data.stroke} />
		}
		plotLines = <VictoryScatter  {...sProps} />
		//TODO interpolation
	}


	let markersProps: VictoryScatterProps = {
		data: markerPoints,
		x: "time",
		y: "value",
		dataComponent: <GraphAlertMarker />
	}

	return (
		<div style={{ height: "100%" }} ref={ref} >
			<VictoryChart {...chartProps}>

				<VictoryAxis dependentAxis tickCount={3} tickLabelComponent={<TickLabel />} />

				{isFinite(thresholdMin) && <VictoryLine style={thresholdStyle} y={() => thresholdMin} samples={1} />}
				{isFinite(thresholdMax) && <VictoryLine style={thresholdStyle} y={() => thresholdMax} samples={1} />}

				{plotLines}

				<VictoryScatter {...markersProps} />

			</VictoryChart>
		</div >
	)
}

export type DoubleDataPointGraphProps = DataPointGraphProps & {
	secondaryGraphData: PatientGraphData
	secondaryThresholdMin?: number
	secondaryThresholdMax?: number
	secondaryYRange?: [number, number]
}

export const DoubleDataPointGraph: FC<DoubleDataPointGraphProps> = ({ graphStyle, endDate, markerPoints, yRange, lineColor, graphData, secondaryGraphData, xRange, thresholdMin = -Infinity, thresholdMax = Infinity }) => {
	const [ref, dimensions] = useDimensions()
	const muiTheme = useTheme()
	const { dataPoints = [], thresholds = [] } = graphData || {}
	const { dataPoints: secondaryDataPoints = [], thresholds: secondaryThresholds = [] } = secondaryGraphData || {}

	const thresholdRed = muiTheme.palette.dataPlot.thresholdStroke
	let thresholdStyle = {
		data: { stroke: thresholdRed, strokeWidth: 1.0 },
	}

	let lineStyle = {
		data: { stroke: lineColor, strokeWidth: 1.25 },
	}

	let theme = {
		...VictoryTheme.material,
		axis: {
			...VictoryTheme.material.axis,
			"style": {
				...VictoryTheme.material.axis?.style,
				axis: {
					stroke: "transparent"
				},
				ticks: {
					stroke: "transparent"
				},
				grid: {
					...VictoryTheme.material.axis?.style?.grid,
					"stroke": muiTheme.palette.dataPlot.gridLineStroke,
					"strokeWidth": 1,
					"strokeDasharray": "1, 1",
				},
			}
		}
	}

	let now = Math.min(endDate, Date.now())

	let currThreshold = sortBy(thresholds, "createdOn").reverse()[0]
	let currThresholdMin = currThreshold?.min
	let currThresholdMax = currThreshold?.max

	let currSecondaryThreshold = sortBy(secondaryThresholds, "createdOn").reverse()[0]
	let currSecondaryThresholdMin = currSecondaryThreshold?.min
	let currSecondaryThresholdMax = currSecondaryThreshold?.max


	let minPoint = now - xRange
	let maxPoint = now

	let chartProps: VictoryChartProps = {
		theme,
		domain: { x: [minPoint, maxPoint], y: yRange ? yRange : undefined },
		domainPadding: { y: 20 },
		width: dimensions.width || 220,
		height: dimensions.height || 60,
		padding: { top: 5, bottom: 5, right: 5, left: 45 },
		containerComponent: <VictoryZoomContainer responsive={true} allowPan={false} allowZoom={false} zoomDimension="x" />
	}

	const TickLabel: FC<VictoryLabelProps> = (props) => {
		if (props.text === `${currThresholdMin}` || props.text === `${currThresholdMax}` || props.text === `${currSecondaryThresholdMin}` || props.text === `${currSecondaryThresholdMax}`) {
			return <VictoryLabel {...props} style={[{ fill: thresholdRed }]} />
		}
		return <VictoryLabel {...props} />
	}

	let plotLines
	let secondaryPlotLines
	let primaryInterpolation
	let secondaryInterpolation


	let pDp = dataPoints.filter(dp => dp.value !== undefined)
	let sDp = secondaryDataPoints.filter(dp => dp.value !== undefined)
	
	if (graphStyle === "line") {
		let lProps: VictoryLineProps = {
			style: lineStyle,
			data: pDp,
			x: "time",
			y: "value",
		}
		plotLines = <VictoryLine  {...lProps} />

		let secondaryLineProps: VictoryLineProps = {
			style: lineStyle,
			data: sDp,
			x: "time",
			y: "value",
		}
		secondaryPlotLines = <VictoryLine  {...secondaryLineProps} />

	} else if (graphStyle === "plot" || graphStyle === "interpolation"){

		let lProps: VictoryScatterProps = {
			style: lineStyle,
			data: pDp,
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={lineStyle.data.stroke} />
		}
		plotLines = <VictoryScatter  {...lProps} />

		let secondaryLineProps: VictoryScatterProps = {
			style: lineStyle,
			data: sDp,
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={lineStyle.data.stroke} />
		}
		secondaryPlotLines = <VictoryScatter  {...secondaryLineProps} />
		
		if (graphStyle === "interpolation") {
			
			if (pDp.length > 1) {
				let lProps: VictoryLineProps = {
					style: lineStyle,
					data: pDp,
					x: "time",
					y: "value",
					interpolation: "monotoneX"
				}
				primaryInterpolation = <VictoryLine  {...lProps} />
			}
	
			if (sDp.length > 1) {
				let secondaryLineProps: VictoryLineProps = {
					style: lineStyle,
					data: sDp,
					x: "time",
					y: "value",
					interpolation: "monotoneX"
				}
				secondaryInterpolation = <VictoryLine  {...secondaryLineProps} />
			}
			
		}

	}



	let markersProps: VictoryScatterProps = {
		data: markerPoints,
		x: "time",
		y: "value",
		dataComponent: <GraphAlertMarker />
	}

	return (
		<div style={{ height: "100%" }} ref={ref} >
			<VictoryChart {...chartProps}>

				<VictoryAxis dependentAxis tickCount={3} tickLabelComponent={<TickLabel />} />

				{isFinite(thresholdMin) && <VictoryLine style={thresholdStyle} y={() => thresholdMin} samples={1} />}
				{isFinite(thresholdMax) && <VictoryLine style={thresholdStyle} y={() => thresholdMax} samples={1} />}
				{primaryInterpolation}
				{plotLines}
				{secondaryInterpolation}
				{secondaryPlotLines}

				<VictoryScatter {...markersProps} />

			</VictoryChart>
		</div >
	)
}

export default DataPointGraph