import { ApolloError, useQuery } from '@apollo/client'

import gql from 'graphql-tag'

import { DateTime, ID } from './types'

const LIST_ECG_READINGS = gql`
query ListEcgReadings($patientId:ID!, $intervalId:ID!) {
	listEcgReadings(patientId: $patientId, intervalId: $intervalId) {
		id
		time
		classification
		alert {
			id
			dismissed
		}
		symptoms
	}	
}`


export type EcgReadingListItem = {
	id: ID
	time: DateTime
	classification: number
	alert?: {
		id:ID
		dismissed:boolean
	}
	symptoms: string[]
}

export const useGetEcgReadings = (patientId: ID, intervalId:ID): { loading: boolean; error?: ApolloError; readings?: Array<EcgReadingListItem>; } => {
	const gqlQuery = LIST_ECG_READINGS
	const variables = {
		patientId,
		intervalId,
	}
	const q = useQuery<{ listEcgReadings:Array<EcgReadingListItem> }>(gqlQuery, { variables })
	return {
		...q,
		readings: q.data?.listEcgReadings,
	}
}


const GET_ECG_READING = gql`
query GetEcgReading($patientId:ID!, $readingId:ID!) {
	ecgReading(patientId: $patientId, readingId: $readingId) {
		id
		time
		data {
			o
			v
		}
		classification
		pulse
		frequency
		numberOfMeasurements
		symptoms
	}	
}`


export type EcgReading = {
	id: ID
	time: DateTime
	data: Array<{o:number, v:number}>
	classification: number
	pulse: number
	frequency: number
	numberOfMeasurements: number
	symptoms: string[]
}

export const useGetEcgReading = (patientId: ID, readingId?:ID): { loading: boolean; error?: ApolloError; ecgReading?: EcgReading; } => {
	const gqlQuery = GET_ECG_READING
	const variables = {
		patientId,
		readingId,
	}
	let skip = !readingId
	const q = useQuery<{ ecgReading:EcgReading }>(gqlQuery, { skip, variables })
	return {
		...q,
		ecgReading: q.data?.ecgReading
	}
}
