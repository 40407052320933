import React, { FC, useState, useEffect, useRef, MutableRefObject } from 'react';

import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, useTheme, Typography, useMediaQuery, FormControlLabel, Checkbox } from '@material-ui/core'
import { min, max, range } from 'underscore'
import { VictoryTheme, VictoryAxis, VictoryChart, VictoryLine, VictoryChartProps, VictoryLineProps, VictoryBrushContainer, VictoryZoomContainerProps, VictoryBrushContainerProps, createContainer, VictoryVoronoiContainerProps, LineSegment } from 'victory';

import { format } from 'utils/date'
import { DateTime, IPatientInfo, DataPointType } from 'store'
import { EcgSymptomList, GetDataPointString } from 'components';
import { getClassificationType } from 'utils'

const useDimensions = (): [MutableRefObject<HTMLDivElement>, { width?: number, height?: number }] => {
	const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
	const [dimensions, setDimensions] = useState<{ width?: number, height?: number }>({
		width: undefined,
		height: undefined,
	})

	useEffect(() => {
		let handleResize = () => {
			if (ref.current) {
				setDimensions(ref.current.getBoundingClientRect().toJSON())
			}
		}

		window.addEventListener("resize", handleResize);
		handleResize()
		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, [ref])

	return [ref, dimensions]
}

const SCRUBBER_HEIGHT = 60

type ScrubberProps = {
	minPoint: number,
	maxPoint: number
	width: number,
	paddingLeft: number,
	handleBrush: (domain: any) => void
	data: Array<EcgDataPoint>,
	selectedDomain: { x: [number, number], y: [number, number] }
}

const Scrubber: FC<ScrubberProps> = ({ data, width, minPoint, maxPoint, paddingLeft, handleBrush, selectedDomain }) => {
	const muiTheme = useTheme()
	const lineColor = muiTheme.palette.alertRed//.dataPlot.line
	const [downsampled, setDownsampled] = useState<Array<EcgDataPoint>>([])
	useEffect(() => {
		let samples: Array<EcgDataPoint> = [data[0]]
		let lastPoint = data[1]
		let dir: "incr" | "decr" = "incr"
		for (let i = 2; i < data.length; i++) {
			let point = data[i]
			if (point.v < lastPoint.v && dir === "incr") {
				// inflection point
				samples.push(lastPoint)
				dir = "decr"
			} else if (point.v > lastPoint.v && dir === "decr") {
				// inflection point
				samples.push(lastPoint)
				dir = "incr"
			}
			lastPoint = point
		}
		setDownsampled(samples)

	}, [data])

	let brushContainerContainrProps: VictoryBrushContainerProps = {
		responsive: false,
		brushDimension: "x",
		brushDomain: selectedDomain,
		onBrushDomainChange: handleBrush,
		defaultBrushArea: "move",
		brushStyle: { stroke: "transparent", fill: muiTheme.palette.primary.light, fillOpacity: 0.5 }
	}

	let lineStyle = {
		data: { stroke: lineColor, strokeWidth: 1.25 },
	}

	let scrubberChartProps: VictoryChartProps = {
		domain: { x: [minPoint, maxPoint] },
		width,
		height: SCRUBBER_HEIGHT,
		padding: { top: 0, right: 0, left: paddingLeft, bottom: 0 },
		scale: { x: "linear" },
		containerComponent: <VictoryBrushContainer {...brushContainerContainrProps} />
	}

	let fullLineProps: VictoryLineProps = {
		style: lineStyle,
		data: downsampled,
		x: "o",
		y: "v",
	}
	let plotLineFull = <VictoryLine  {...fullLineProps} />


	return (
		<VictoryChart {...scrubberChartProps}>
			<VictoryAxis domain={[minPoint, maxPoint]} tickLabelComponent={<></>} />
			{plotLineFull}
		</VictoryChart>
	)
}


const GridLineComponent: FC<{ datum?: number, getColor: (data: number) => string }> = (props) => {
	let color = props.getColor(props.datum || 0)
	return <LineSegment {...props} style={{ stroke: color }} />
}

type EcgDataPoint = { o: number, v: number }
export type EcgGraphProps = {
	time?: DateTime
	timeOffset?: number
	data: Array<EcgDataPoint>,
	allowZoom?: boolean
	forPrint?: boolean
	maxTime?: number
}

const VictoryZoomVoronoiContainer = createContainer<VictoryZoomContainerProps, VictoryVoronoiContainerProps>("zoom", "voronoi")

const xInitialRange = 86400000
export const EcgGraph: FC<EcgGraphProps> = ({ allowZoom, data, timeOffset, maxTime, forPrint }) => {
	const [ref, dimensions] = useDimensions()
	const muiTheme = useTheme()
	let now = Date.now()
	const [selectedDomain, setSelectedDomain] = useState<any>({ x: [now - xInitialRange, now] })
	const [zoomDomain, setZoomDomain] = useState<any>({ x: [now - xInitialRange, now] })
	const [graphedData, setGraphedData] = useState<Array<EcgDataPoint>>([])

	let oVals = data.map(d => d.o)
	let minPoint: number = min(oVals) || 0
	let maxPoint = max(oVals) || 0

	const initViewSeconds = forPrint ? 10 : 3
	useEffect(() => {
		let start = timeOffset === undefined ? minPoint : (minPoint + timeOffset)
		let dMax = start + (maxTime ? maxTime : initViewSeconds)
		setSelectedDomain({ x: [start, dMax] })
		setZoomDomain({ x: [start, dMax] })
		setGraphedData(data.filter(d => d.o >= start && d.o <= dMax))
	}, [data, minPoint, maxPoint, timeOffset, initViewSeconds, maxTime])

	if (!data || data.length < 2) {
		return <></>
	}
	const lineColor = muiTheme.palette.alertRed

	let lineStyle = {
		data: { stroke: lineColor, strokeWidth: 1.25 },
	}

	let theme = {
		...VictoryTheme.material,
		axis: {
			...VictoryTheme.material.axis,
			"style": {
				...VictoryTheme.material.axis?.style,
				axis: {
					stroke: "transparent"
				},
				ticks: {
					stroke: "transparent"
				},
				grid: {
					...VictoryTheme.material.axis?.style?.grid,
					"stroke": muiTheme.palette.dataPlot.gridLineStroke,
					"strokeWidth": 1,
					"strokeDasharray": "1, 1",
				},
			}
		}
	}

	const handleZoom = (domain: any): void => {
		setSelectedDomain({ ...domain, y: [yMin, yMax] })
		setZoomDomain({ ...domain, y: [yMin, yMax] })
		setGraphedData(data.filter(d => d.o >= domain.x[0] && d.o <= domain.x[1]))
	}

	const handleBrush = (domain: any): void => {
		setZoomDomain({ ...domain, y: [yMin, yMax] })
		setSelectedDomain({ ...domain, y: [yMin, yMax] })
		setGraphedData(data.filter(d => d.o >= domain.x[0] && d.o <= domain.x[1]))
	}

	let zoomContainerProps = {
		responsive: false,
		allowZoom: !!allowZoom,
		zoomDimension: "x" as const,
		zoomDomain,
		onZoomDomainChange: handleZoom,
		labels: ({ datum }: { datum: EcgDataPoint }) => {
			return `${datum.o ? datum.o.toFixed(2) + 's' : '0s'}, ${GetDataPointString(DataPointType.Ecg, "unit", datum.v)}`
		},
	}

	const X_AXIS_HEIGHT = 25
	const paddingLeft = 58

	let yMin = -650//Math.min(-440, ((min(data, d => d.v) as EcgDataPoint).v || 0))
	let yMax = Math.max(650, ((max(data, d => d.v) as EcgDataPoint).v || 0))

	let chartProps: VictoryChartProps = {
		theme,
		domain: { x: [minPoint, maxPoint], y: [yMin, yMax] },
		width: forPrint ? 600 : (dimensions.width || 900),
		height: forPrint ? 220 : (dimensions.height || 500) - (allowZoom ? SCRUBBER_HEIGHT : 0) - X_AXIS_HEIGHT,
		padding: { top: 20, bottom: 20, right: 0, left: paddingLeft },
		containerComponent: <VictoryZoomVoronoiContainer {...zoomContainerProps} />
	}

	let chartAxisProps: VictoryChartProps = {
		...chartProps,
		domain: selectedDomain,
		height: X_AXIS_HEIGHT,
		padding: { top: 20, bottom: 0, right: 0, left: paddingLeft },
		containerComponent: undefined,
	}

	let lineProps: VictoryLineProps = {
		style: lineStyle,
		data: graphedData,
		x: "o",
		y: "v",
	}
	let plotLine = <VictoryLine  {...lineProps} />

	let xMin = (min(data, d => d.o) as EcgDataPoint).o
	let xMax = (max(data, d => d.o) as EcgDataPoint).o

	let secondsTicks = range(Math.floor(xMin), Math.ceil(xMax), 1)
	//let fifthSecondsTicks = range(xMin, xMax, 0.04)
	let valTicks = range(-650, yMax + 1, 25)

	const getXAxisColor = (data: number) => {
		let v = Math.round(data * 100)
		if (v === 0 || v % 100 === 0) {
			// seconds
			return "#a3a3a3"
		} else if (v % 20 === 0) {
			// 1/5th seconds
			return "#c7c7c7"
		} else {
			// other ticks
			return "#ededed"
		}
	}

	const getYAxisColor = (data: number) => {
		let v = Math.round(data)

		if (v === 0 || Math.abs(v) === 650) {
			return "#5c5c5c"
		} else if (v % 150 === 0) {
			return "#a3a3a3"
		} else if (v % 50 === 0) {
			return "#c7c7c7"
		} else {
			// other ticks
			return "#ededed"
		}
	}

	const getLabelsAxisColor = (): string => {
		return "transparent"
	}

	const getYTick = (val: number): string => {
		if (val === 0 || Math.abs(val) === 650) {
			return `${(val === 0 ? 0 : val / 1000).toFixed(2)}mV`
		}
		return ""
	}

	const scrubberProps = {
		selectedDomain,
		handleBrush,
		paddingLeft,
		width: forPrint ? 400 : (dimensions.width || 500),
		minPoint,
		maxPoint,
		data,
	}

	return (
		<div style={{ flex: 1, height: "100%", width: "100%" }} ref={ref} >
			<VictoryChart {...chartProps}>
				<VictoryAxis dependentAxis tickValues={valTicks} gridComponent={<GridLineComponent getColor={getYAxisColor} />} tickFormat={getYTick} />
				<VictoryAxis offsetY={20} tickValues={secondsTicks} tickFormat={(v, t) => {
					if (forPrint && t === 10) {
						return ''
					}
					return `${v}s`
				}} gridComponent={<GridLineComponent getColor={getXAxisColor} />} />
				{plotLine}
			</VictoryChart>
			{!forPrint && <Box height={`${X_AXIS_HEIGHT}px`}>
				<VictoryChart {...chartAxisProps}>
					<VictoryAxis orientation="top" gridComponent={<GridLineComponent getColor={getLabelsAxisColor} />} tickValues={secondsTicks} tickFormat={(val) => ``} />
				</VictoryChart>
			</Box>}
			{allowZoom && <Scrubber {...scrubberProps} />}
		</div>
	)
}

const PrintStackedGraphs: FC<{ forcePrint: boolean, timeStart: DateTime, data: Array<{ o: number, v: number }> }> = ({ data, forcePrint }) => {
	const intervals = 3
	const incr = (30) / intervals
	const times = range(0, intervals).map(mult => mult * incr)
	return (
		<Box className={forcePrint ? "" : "printOnlyBlock"} flex={1} display="flex" flexDirection="column" overflow="hidden">
			{times.map(time => {
				return (
					<Box key={time} height="24rem" width="60rem" display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="relative" overflow="hidden" margin={0}>
						<EcgGraph data={data} timeOffset={time} maxTime={incr} forPrint />
					</Box>
				)
			})}
		</Box>
	)
}

export type EcgGraphModalProps = {
	open: boolean;
	patient: IPatientInfo,
	onClose: () => void;
	time: DateTime;
	classification: number,
	symptoms: Array<string>,
	pulse: number,
	data: Array<{ o: number, v: number }>
}

const useStyles = makeStyles(() => ({
	dialogPaper: {
		minHeight: '90vh',
		maxHeight: '90vh',
	},
	dialogPaperPrint: {
		height: '100vh',

	}
}))

export const EcgGraphModal: FC<EcgGraphModalProps> = ({ onClose, open, patient, time, symptoms, classification, data, pulse }) => {
	const classes = useStyles()
	const [forcePrint, setForcePrint] = useState(false)
	const inPrint = useMediaQuery("print") || forcePrint

	//@ts-ignore
	const isFirefox = typeof InstallTrigger !== 'undefined'

	useEffect(() => {
		if (!open) {
			return
		}
		document.body.className += ' printModal'
		return () => {
			document.body.className = document.body.className.replace(/\sprintModal/ig, "")
		}
	}, [open])

	return (
		<Dialog classes={{ paper: inPrint ? classes.dialogPaperPrint : classes.dialogPaper }} fullWidth maxWidth="xl" onClose={onClose} open={open}>
			<DialogTitle>
				<Box>
					<Box>
						{patient.firstName} {patient.lastName} - ECG {format(new Date(time), "MM/dd/yyyy hh:mm a")}: {getClassificationType(classification)} - Pulse {pulse}
					</Box>
					{!!symptoms?.length && <Box>
						<Typography variant="subtitle1">Symptoms: <EcgSymptomList symptoms={symptoms} /></Typography>
					</Box>}
				</Box>
				{isFirefox && <Box className="printHidden">
					<FormControlLabel control={<Checkbox checked={forcePrint} onChange={(evt) => setForcePrint(evt.target.checked)} />} label="For Print" />
				</Box>}
			</DialogTitle>
			<DialogContent style={{ display: "flex", flexDirection: "column", overflowY: inPrint ? "visible" : "hidden", flex: 1 }}>
				<PrintStackedGraphs forcePrint={forcePrint} data={data} timeStart={time} />
				{!inPrint && <Box className="printHidden" flex={1} display="flex" flexDirection="column">
					<Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="relative">
						<EcgGraph allowZoom data={data} time={time} />
					</Box>
				</Box>}
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={onClose} color="primary">Close</Button>
			</DialogActions>
		</Dialog>
	);
}

export default EcgGraphModal