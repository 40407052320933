import React, { FC } from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'
import { useParams, Switch, Route, Redirect } from 'react-router'
import { differenceInYears } from 'date-fns'

import { format } from 'utils/date'
import PageBanner from 'components/PageBanner'
import { useGetPatientInfo, IPatientInfo, useGetPracticeInfo } from 'store'
import { ErrorBox, CenteredLoader } from 'components'
import PatientInfoSidebar from './PatientInfoSidebar'
import IntervalNotesSidebar from './IntervalNotesSidebar'
import PatientDataView from './PatientDataView'
import IntervalSelector from './IntervalSelector'
import HistoryList from './HistoryList'
import SignoffView from './Signoff'
import PrintSignoff from './PrintSignoff'

const InterlvalDetailsView: FC<{ patient?: IPatientInfo, intervalId?: string }> = ({ patient, intervalId }) => {
	let selectedInterval = patient?.intervals.find(i => i.id === intervalId) || patient?.intervals[0]
	return <>
		<Box display="flex" flex={1} flexDirection="column" overflow="hidden">
			<SignoffView />
			<Box display="flex" flex={1} overflow="hidden">
				<Box flex={7.5} height="100%" overflow="auto">
					{!!patient && <PatientDataView patient={patient} selectedInterval={selectedInterval} />}
				</Box>
				<Box flex={3.6} minWidth="36rem" height="100%" position="relative" overflow="auto" className="printHidden">
					<IntervalNotesSidebar selectedInterval={selectedInterval} />
				</Box>
			</Box>
		</Box>
	</>
}

const usePrintPatientInfoStyles = makeStyles(theme => ({
	root: {
		borderTop: `solid 0.3rem ${theme.palette.borderColor}`,
		borderBottom: `solid 0.3rem ${theme.palette.borderColor}`,
	},
	noteHeader: {
		padding: "1rem 0.5rem",
		fontSize: "1.4rem",
		fontWeight: "bold",
	},
	reportNoteHeader: {
		padding: "1rem 0.5rem",
		fontSize: "1.4rem",
		fontWeight: "bold",
	},
	noteSplitter: {
		width: "0rem",
		margin: "5.5rem 0.5rem 1rem",
		borderLeft: `solid 0.2rem ${theme.palette.borderColor}`,
	},
	noteContent: {
		padding: "0.5rem",
		whiteSpace: "pre-wrap",
	}
}))

const PrintPatientIntervalInfo: FC = () => {
	const { patientId, intervalId } = useParams<{ patientId: string, intervalId?: string }>()
	const { patient } = useGetPatientInfo(patientId)
	const styles = usePrintPatientInfoStyles()
	let selectedInterval = patient?.intervals.find(i => i.id === intervalId)
	if (!patient) {
		return <></>
	}
	return (
		<Box pt={15} style={{pageBreakBefore:"always", pageBreakInside: "avoid"}} className="printOnlyBlock">
			<PrintPatientInfo />
			<Box display="flex" flexDirection="column" mt={1} mb={1} mx={1}>
				<Box flex={1}>
					<Box className={styles.reportNoteHeader}>
						Patient Report Note
				</Box>
					<Box className={styles.noteContent}>
						{selectedInterval?.reportNote}
					</Box>
				</Box>
				<Box flex={1} mt={3}>
					<Box className={styles.noteHeader}>
						Internal Patient Note:
				</Box>
					<Box className={styles.noteContent}>
						{selectedInterval?.internalNote}
					</Box>
				</Box>
				<Box flex={1} mt={3}>
					<Box className={styles.noteHeader}>
						Patient Note:
				</Box>
					<Box className={styles.noteContent}>
						{patient.patientNote}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

const PrintPatientInfo: FC = () => {
	const styles = usePrintPatientInfoStyles()
	const { patientId } = useParams<{ patientId: string, intervalId?: string }>()
	const { patient } = useGetPatientInfo(patientId)
	return (
		<>
			<Box className={`${styles.root} printOnlyBlock`}>
				<Box display="flex" justifyContent="space-between" px={1} py={0.3} alignItems="center" className="printSmallText">
					<Box>
						<Typography variant="body1">
							Age: {patient?.dob && differenceInYears(Date.now(), new Date(patient.dob + " 00:00:00"))}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body1">
							DOB: {patient?.dob && format(new Date(patient.dob + " 00:00:00"), "MM/dd/yyyy")}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body1">
							Sex: {patient?.sex}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body1">
							Phone: {patient?.phone}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body1">
							ID: {patient?.mrn}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body1">
							Indication: {patient?.indication}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body1">
							Physician: {patient?.physician}
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

const PatientDetailsView: FC = () => {
	const { patientId, intervalId } = useParams<{ patientId: string, intervalId?: string }>()
	const { patient, loading:loadingPatient, error:patientError } = useGetPatientInfo(patientId)
    const practiceInfo = useGetPracticeInfo()

    const error = patientError || practiceInfo.error
	const loading = loadingPatient || practiceInfo.loading
	
	return (
		<Box display="flex" flexDirection="column" flex={1} className="printDisplayBlock">
			<Route path="/patients/:patientId/:intervalId?" component={PrintPatientInfo} />
			<Box flex={1} position="relative">
				<Box position="absolute" display="flex" width="100%" height="100%" overflow="hidden" className="printOverflowVisible printPositionRelative">
					<Box flex={2.5} minWidth="26rem" height="100%" position="relative" overflow="auto" className="printHidden">
						<PatientInfoSidebar patient={loading ? undefined : patient} isCustomInterval={!!practiceInfo.practice?.customInterval} hasBodyTrace={!!practiceInfo.practice?.hasBodyTrace} hasSmartMeter={!!practiceInfo.practice?.hasSmartMeter}/>
					</Box>
					<Box flex={11} display="flex" flexDirection="column" height="100%" position="relative" overflow="hidden" className="printOverflowVisible">
						<PageBanner className="printHidden">
							<IntervalSelector patient={patient} />
						</PageBanner>
						<Box flex={1} display="flex" position="relative" overflow="hidden" className="printOverflowVisible">
							<CenteredLoader flex={1} loading={loading} />
							{!!error && <ErrorBox flex={1} my={2} message="Failed to load data" />}
							{!error && !loading &&
								<Switch>
									<Route path="/patients/:patientId/history" component={HistoryList} />
									<Route path="/patients/:patientId/:intervalId" render={() => <InterlvalDetailsView intervalId={intervalId} patient={patient} />} />
									<Redirect to={`/patients/${patientId}/${patient?.activeInterval?.id || "history"}`} />
								</Switch>
							}
						</Box>
					</Box>
				</Box>
			</Box>
			<Route path="/patients/:patientId/:intervalId?" component={PrintPatientIntervalInfo} />
			<Route path="/patients/:patientId/:intervalId" component={PrintSignoff} />
		</Box>
	)
}

export default PatientDetailsView
