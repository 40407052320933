export type DateTime = number

export type ID = string

export enum DataPointType {
	Pulse = 'Pulse',
	StepCounter = 'StepCounter',
	Sleep = 'Sleep',
	O2 = 'O2',
	BloodPressure = 'BloodPressure',
	Weight = 'Weight',
	Ecg = 'Ecg',
	Systolic = 'Systolic',
	Diastolic = 'Diastolic'
}

export enum SortOrder {
	Asc = 'Asc',
	Desc = 'Desc',
}
