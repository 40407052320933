import React, { FC, useState, useEffect } from 'react'

import { ApolloProvider } from '@apollo/client'
import { Switch, Route, Redirect, BrowserRouter as Router, useLocation } from 'react-router-dom'
import { CssBaseline, ThemeProvider, Box, CircularProgress } from '@material-ui/core'
import { IntlProvider } from 'react-intl'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Amplify, { Auth } from 'aws-amplify'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'

import apolloClient, { setJwtToken } from 'store/apolloClient'

import LeftToolbar from './views/LeftToolbar/LeftToolbar'
import Header from './views/Header'
import PatientList from './views/pages/PatientList'
import Dashboard from './views/pages/Dashboard'
import BillingView from './views/pages/BillingView'
import OnboardingView from './views/pages/OnboardingView'
import AddPatientView from './views/pages/AddPatientView'
import DevicesView from './views/pages/DevicesView'
import PatientDetailView from './views/pages/PatientDetailView'
import LoginView from './views/pages/LoginView'

import { useTheme } from './theme'
import { withAuthenticator } from '@aws-amplify/ui-react'


const { REACT_APP_AWS_COGNITO_REGION = "", REACT_APP_AWS_COGNITO_USER_POOL_ID = "", REACT_APP_AWS_COGNITO_CLIENT_ID = "" } = process.env

const aws_cognito_region = REACT_APP_AWS_COGNITO_REGION
const aws_user_pools_id = REACT_APP_AWS_COGNITO_USER_POOL_ID
const aws_client_id = REACT_APP_AWS_COGNITO_CLIENT_ID

Amplify.configure({
	aws_cognito_region,
	aws_user_pools_id,
	aws_user_pools_web_client_id: aws_client_id,
	mandatorySignIn: true,
})

const IDLE_TIMEOUT_MS = 30 * 60 * 1000 // 30 minute idle timeout
const useIdleTimeoutLogout = () => {
	const [lastClickAt, setLastClickAt] = useState<number | undefined>(undefined)
	useEffect(() => {
		const timeoutCheck = setTimeout(()=> {
			Auth.signOut()
			document.location.reload()
		}, IDLE_TIMEOUT_MS) // check every minute
		
		return () => {
			window.clearTimeout(timeoutCheck)
		}
	}, [lastClickAt])

	useEffect(() => {
		const clickHandlerTimerReset = () => {
			setLastClickAt(Date.now())
		}
		
		document.addEventListener('mousedown', clickHandlerTimerReset, false)
		return () => {
			document.removeEventListener('mousedown', clickHandlerTimerReset, false)
		}
	}, [])

}

const App: FC = () => {
	//let { user, loaded } = useUser()

	const [authState, setAuthState] = useState<AuthState | undefined>()
	const [user, setUser] = useState<object | undefined>()
	
	useIdleTimeoutLogout()
	
	useEffect(() => {
		return onAuthUIStateChange(async (nextAuthState, authData) => {
			setAuthState(nextAuthState)
			if (nextAuthState === AuthState.SignedIn) {
				let user = await Auth.currentSession()
				let jwt = user.getIdToken().getJwtToken()
				setJwtToken(jwt)
				setUser(authData)
			} else {
				setUser(undefined)
				setJwtToken(undefined)
			}
		})
	}, [])
	
	const route = useLocation()
	const isBilling = route.pathname.toLowerCase().indexOf("/billing") > -1


	if (!!user) {
		return (
			<>
				<Header />
				<LeftToolbar />
				<Box pl={7.2} mt={9.2} width="100%" flex={1} component="main" display="flex" overflow="auto" className={isBilling ? 'billing printDisplayBlock' : "printDisplayBlock"}>
					<Switch>
						<Route path="/dashboard" component={Dashboard} />
						<Route path="/patients/new" component={AddPatientView} />
						<Route path="/patients/:patientId/:intervalId?" component={PatientDetailView} />
						<Route path="/patients" component={PatientList} />
						<Route path="/billing" component={BillingView} />
						<Route path="/onboard" component={OnboardingView} />
						<Route path="/devices" component={DevicesView} />
						<Redirect to="/dashboard" />
					</Switch>
				</Box>
			</>
		)
	} else if (authState === AuthState.Loading) {
		return (
			<Box width="100%" height="100%" flex={1} display="flex" justifyContent="center" alignItems="center">
				<CircularProgress size={40} />
			</Box>
		)
	} else {
		return (
			<LoginView />
		)
	}
}

const Providers: FC = () => {
	const [locale, /*_setLocale*/] = useState<string>("en")
	const theme = useTheme()
	return (
		<IntlProvider locale={locale}>
			<ApolloProvider client={apolloClient}>
				<Router basename={process.env.PUBLIC_URL}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<App />
						</MuiPickersUtilsProvider>
					</ThemeProvider>
				</Router >
			</ApolloProvider>
		</IntlProvider>
	)
}

export default withAuthenticator(Providers)
