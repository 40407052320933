import { ApolloError, useMutation, useQuery } from '@apollo/client'

import gql from 'graphql-tag'

import { DataPointType, DateTime, ID, SortOrder } from './types'
import { ExecutionResult } from 'graphql'

import {onError, update} from './utils'

const LIST_ACTIVE_ALERTS_BY_PATIENT = gql`
query ActiveAlertsByPatient($pageIndex:Int, $rowsPerPage: Int, $patientFilter:PatientFilter!, $alertFilter:PatientAlertFilter!) {
	patients (pageIndex:$pageIndex, rowsPerPage: $rowsPerPage, filter:$patientFilter) {
		count
		results {
			id
			firstName
			lastName
			alerts(filter:$alertFilter) {
				count
				results {
					id
					dismissed
					startedOn
					endedOn
					thresholdMin
					thresholdMax
					alertValue
					markers {
						id
						time
						value
					}
					dataPoints {
						id
						time
						value
					}
					dataPointType
					ecgReading {
						id
						pulse
						classification
						symptoms
					}
				}
			}
		}
	}
}`

export interface IActiveAlertsByPatientFilter {
	dataPoints?: Array<DataPointType>
}


export type ActiveAlertData = {
	id: ID
	dismissed: boolean
	startedOn: DateTime
	endedOn?: DateTime
	thresholdMin?: number
	thresholdMax?: number
	alertValue: number
	markers: Array<{
		id: ID
		time: DateTime
		value: number
	}>
	dataPoints: Array<{
		id: ID
		time: DateTime
		value?: number
	}>
	dataPointType: DataPointType
	ecgReading?: {
		id: ID
		pulse: number
		classification: number
		symptoms: string[]
	}
}

export type ActiveAlertsByPatientListData = {
	id: ID;
	firstName: string
	lastName: string
	alerts: {
		count: number;
		results: Array<ActiveAlertData>;
	};
}


interface IPatientAlertsRawQueryResults {
	count: number;
	results: Array<ActiveAlertsByPatientListData>
}

export const useGetAlertCards = (filter?: IActiveAlertsByPatientFilter, pageIndex?: number, rowsPerPage?: number): { loading: boolean; error?: ApolloError; count?: number; patients?: Array<ActiveAlertsByPatientListData>; } => {
	const gqlQuery = LIST_ACTIVE_ALERTS_BY_PATIENT
	const variables = {
		pageIndex,
		rowsPerPage,
		alertFilter: {
			...filter,
			dismissed: false,
		},
		patientFilter: {
			...filter, // need to filter by dataPointType here as well
			alertStatus: 'Alerts',
		},
	}
	const q = useQuery<{ patients: IPatientAlertsRawQueryResults }>(gqlQuery, { variables })
	return {
		...q,
		patients: q.data?.patients?.results,
		count: q.data?.patients?.count,
	}
}

const LIST_ALERTS = gql`
query ListAlerts($pageIndex:Int, $rowsPerPage: Int, $sortOrder:SortOrder, $sortBy:AlertSortType, $filter:AlertFilter) {
	alerts (pageIndex:$pageIndex, rowsPerPage: $rowsPerPage, sortOrder:$sortOrder, sortBy:$sortBy, filter:$filter) {
		count
		results {
			id
			intervalId
			dismissed
			startedOn
			endedOn
			thresholdMin
			thresholdMax
			alertValue
			markers {
				id
				time
				value
			}
			dataPointType
			patient {
				id
				firstName
				lastName
			}
			ecgReading {
				id
				pulse
				classification
				symptoms
			}
		}
	}
}`

export interface IAlertListFilter {
	dismissed?: boolean
	patientId?: ID
	dataPoints?: Array<DataPointType>
	watchedPatientsOnly?:boolean
}

export type AlertListData = {
	id: ID
	dismissed: boolean
	startedOn: DateTime
	endedOn?: DateTime
	alertValue: number
	thresholdMin?: number
	thresholdMax?: number
	/*dataPoints: Array<{
		time: DateTime
		value?: number
	}>*/
	dataPointType: DataPointType
	patient: {
		id: ID
		firstName: string
		lastName: string
	}
	markers: Array<{
		id: ID
		time: DateTime
		value: number
	}>
	intervalId:ID
	ecgReading?: {
		id: ID
		pulse: number
		classification: number
		symptoms: string[]
	}
}


interface IRawQueryResults {
	count: number;
	results: Array<AlertListData>;
}


export enum AlertSortType {
	ID = 'ID',
	Name = 'Name',
	Dismissed = 'Dismissed',
}

export const useGetAlerts = (filter?: IAlertListFilter, pageIndex?: number, rowsPerPage?: number, sortBy?: AlertSortType, sortOrder?: SortOrder): { loading: boolean; error?: ApolloError; count?: number; alerts?: Array<AlertListData>; } => {
	const gqlQuery = LIST_ALERTS
	const variables = {
		pageIndex,
		rowsPerPage,
		filter:{
			...filter,
			dismissed: false,
		},
		sortBy,
		sortOrder,
	}
	const q = useQuery<{ alerts: IRawQueryResults }>(gqlQuery, { variables })
	return {
		...q,
		alerts: q.data?.alerts?.results,
		count: q.data?.alerts?.count,
	}
}



const DISMISS_ALERT = gql`
mutation DismissAlert($alertId:ID!) {
	dismissAlert(id:$alertId) {
		id
		dismissed
	}
}`


type DismissAlertMutation = [(alertId: ID) => Promise<ExecutionResult>, { loading: boolean; error?: Error; called:boolean;}]
export const useDismissAlert = (): DismissAlertMutation => {
	const gqlQuery = DISMISS_ALERT

	const mut = useMutation<{ dismissAlert: { id: string } }>(gqlQuery, { update, onError })
	const fn = (alertId: ID): Promise<ExecutionResult> => {
		const variables = { alertId }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}

const DISMISS_ALL_ALERT = gql`
mutation DismissAllAlert($alertIds:[ID!]!) {
	dismissAllAlerts(ids:$alertIds) {
		id
		dismissed
	}
}`


type DismissAllAlertsMutation = [(alertIds: Array<ID>) => Promise<ExecutionResult>, { loading: boolean; error?: Error; }]
export const useDismissAllAlerts = (): DismissAllAlertsMutation => {
	const gqlQuery = DISMISS_ALL_ALERT

	const mut = useMutation<{ dismissAllAlerts: Array<{ id: string }> }>(gqlQuery, { update, onError })
	const fn = (alertIds: Array<ID>): Promise<ExecutionResult> => {
		const variables = { alertIds }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}

const DISMISS_ALL_ALERT_FOR_PATIENT = gql`
mutation DismissAllAlertForPatient($patientId:ID!, $dataPointType:DataPointType) {
	dismissAllAlertsForPatient(patientId:$patientId, dataPointType:$dataPointType) {
		id
		dismissed
	}
}`


type DismissAllAlertsForPatientMutation = [(dataPointType?: DataPointType) => Promise<ExecutionResult>, { loading: boolean; error?: Error; called:boolean}]
export const useDismissAllAlertsForPatient = (patientId: string): DismissAllAlertsForPatientMutation => {
	const gqlQuery = DISMISS_ALL_ALERT_FOR_PATIENT
	
	const mut = useMutation<{ dismissAllAlerts: Array<{ id: string }> }>(gqlQuery, { update, onError })
	const fn = (dataPointType?: DataPointType): Promise<ExecutionResult> => {
		const variables = { patientId, dataPointType }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}
