import React, { FC, useState } from 'react'

import { Box, Typography, makeStyles, CircularProgress, IconButton, Button, useTheme, Menu, MenuItem, Tooltip } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { isFinite, sortBy } from 'underscore'

import { DataPointType, IPatientInfo, useGetPatientGraphData, PatientInterval, useDismissAllAlertsForPatient, DataPoint } from 'store'
import { DataPointIcon, DataPointLabel, SnackbarError } from 'components'
import DataPointGraph, { DoubleDataPointGraph } from './DataPointGraph'
import ThresholdEditor from './ThresholdEditor'
import { format } from 'date-fns'

const useAlertButtonStyles = makeStyles((theme) => ({

	root: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
		fontSize: "1.2rem",
		lineHeight: "1rem",
		borderRadius: "999px",
	}
}))

const useAlertStyles = makeStyles((theme) => ({

	cardRoot: {
		border: `solid 0.1rem ${theme.palette.borderColor}`,
		margin: "0.4rem",
		height: "14rem",
		"&.enabled": {

		},
		"&.disabled $metricTitleContainer": {
			color: theme.palette.text.disabled
		},
		"&.red $mainBorder": {
			borderColor: theme.palette.alertRed,
		},
		"&.green $mainBorder": {
			borderColor: theme.palette.alertGreen,
		},
	},
	metricTitleContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		margin: "0.5rem 0",
		width: "12rem",
		borderRight: `solid 0.1rem ${theme.palette.text.primary}`,
		paddingLeft: "0.75rem",

	},
	metric: {
		fontSize: "3rem",
		lineHeight: "3rem",
		fontWeight: "bold",
	},
	unit: {
		fontSize: "1.4rem",
		fontWeight: 600,
		textTransform: "uppercase",
		marginLeft: "0.2rem"
	},
	label: {
		fontSize: "1.4rem",
		fontWeight: 600,
		textTransform: "uppercase",
	},

	mainBorder: {
		height: "100%",
		borderLeft: `solid 1.6rem ${theme.palette.text.disabled}`,
	},
	doubleDataPointTopVal: {
		borderBottom: `solid 0.2rem ${theme.palette.text.primary}`,
	},
	avgLabel: {
		fontWeight: 200
	},
	lastValLabel: {
		fontWeight: 200
	},
	/*mainAlertBorder: {
		borderLeft: `solid 2rem ${theme.palette.alertRed}`,
	},*/
}))

const getXRange = (dataPointType: DataPointType): number => {
	const HOUR_MS = 60 * 60 * 1000
	const DAY_MS = 24 * HOUR_MS

	switch (dataPointType) {
		case DataPointType.StepCounter:
		case DataPointType.O2:
		case DataPointType.BloodPressure:
		case DataPointType.Systolic:
		case DataPointType.Diastolic:
		case DataPointType.Sleep:
		case DataPointType.Weight:
			return DAY_MS * 7
		case DataPointType.Pulse:
		default:
			return DAY_MS * 1
	}

}

export type DataPointCardProps = {
	patient: IPatientInfo,
	dataPoint: DataPointType,
	selectedInterval: PatientInterval
	onShowFullHistory: (dataPointType: DataPointType) => void
}


const toKThousands = (val?: number, decimals?: number): string => {
	if (val === undefined) {
		return ""
	} else if (val > 10000) {
		return `${Math.floor(val / 1000)}K`
	} else if (val > 1000) {
		return `${Math.floor(val / 100) / 10}K`
	} else if (decimals !== undefined && decimals > 0) {
		let multiplier = Math.pow(10, decimals)
		return `${Math.round(val * multiplier) / multiplier}`
	} else {
		return `${Math.round(val)}`
	}
}

const ValueToStr = (isEnabled:boolean, dataPoint:DataPointType, value?:number, error?:Error) => {
	if (error || value === undefined) {
		return ""
	} else if (isEnabled) {
		let decimals = 0
		if (dataPoint === DataPointType.Sleep) {
			decimals = 1
		}
		return `${toKThousands(value, decimals) || ""}`
	} else {
		return ""
	}
	
}

type MetricDisplayProps = {
	currentValue: string
	loading: boolean
	dataPoint: DataPointType
	mostRecentDataPoint?:DataPoint
}
const MetricDisplay: FC<MetricDisplayProps> = ({loading, currentValue, dataPoint, mostRecentDataPoint})=> {
	const styles = useAlertStyles()
	return (
		<Box className={styles.metricTitleContainer}>
			<Box textAlign="left">
				<Typography style={{ lineHeight: "1.5rem" }} variant="body1" className={styles.label}>
					<span style={{fontSize:"2rem", marginRight:"0.25rem", verticalAlign:"-0.5rem"}}>
						<DataPointIcon dataPoint={dataPoint} thresholded />
					</span>
					<DataPointLabel field={"shortLabel"} dataPoint={dataPoint} />
				</Typography>
			</Box>

			{loading && <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center"><CircularProgress size={16} /></Box>}
			{!loading && 
					<>
						<Box flex={1} />
						<Box textAlign="left"  width="100%">
					
							<Box width="100%" height="100%" >
								<Typography variant="body2" className={styles.lastValLabel}>
									Last:
								</Typography>
								
								<Box display="flex" alignItems="flex-end" width="100%" maxWidth="100%" flexWrap="wrap">		
									<Typography variant="body1" className={styles.metric}>
										{currentValue}
									</Typography>
									<Typography variant="body1" className={styles.unit}>
										<DataPointLabel field={"unit"} dataPoint={dataPoint} />
									</Typography>
								</Box>
								<Box>
									{mostRecentDataPoint && format(mostRecentDataPoint.time, "P")}
								</Box>
								<Box>
									{mostRecentDataPoint && format(mostRecentDataPoint.time, "p")}
								</Box>
								
							</Box>
						</Box>
					</>
				}			
		</Box>
	)
}

const DataPointCard: FC<DataPointCardProps> = ({ patient, dataPoint, selectedInterval, onShowFullHistory }) => {
	const styles = useAlertStyles()
	const theme = useTheme()
	const buttonStyles = useAlertButtonStyles()
	const [editThreshold, setEditThreshold] = useState(false)
	const [dismissAlerts, dismissAlertsStatus] = useDismissAllAlertsForPatient(patient.id)
	const { graphData, error, loading } = useGetPatientGraphData(patient.id, selectedInterval.id, dataPoint, { startTime: selectedInterval?.intervalStart, endTime: selectedInterval?.intervalEnd })

	const isEnabled = !!graphData?.dataPoints?.length || selectedInterval?.dataPoints?.indexOf(dataPoint) > -1 // patient.devices.flatMap(d => d.dataFeeds.flatMap(df => df.dataPointType)).indexOf(dataPoint) > -1
	const thresholds = sortBy(graphData?.thresholds || [], "createdOn").reverse()
	const alerts = sortBy(graphData?.alerts.filter(a => !a.dismissed) || [], "startedOn").reverse()
	let { min: thresholdMin = -Infinity, max: thresholdMax = Infinity } = thresholds?.[0] || {}

	let metrics = selectedInterval?.dataPointMetrics?.find(dpm => dpm.dataPointType === dataPoint)

	
	let mostRecentDataPoint = sortBy(graphData?.dataPoints?.filter(d => d.value !== undefined) ||[], "time").reverse()[0]
	
	let currentValue = ValueToStr(isEnabled, dataPoint, mostRecentDataPoint?.value, error)
	
	let currAvgValue = ValueToStr(isEnabled, dataPoint,  metrics?.average, error)

	let thresholdEditorProps = {
		currentMin: thresholdMin,
		currentMax: thresholdMax,
		patientId: patient.id,
		dataPointType: dataPoint,
		onClose: () => setEditThreshold(false),
		open: !!editThreshold,
	}

	let level = ""

	let lineColor = theme.palette.dataPlot.line
	let hasAlerts = alerts.length > 0

	if (isEnabled) {
		if (hasAlerts) {
			level = "red"
			lineColor = theme.palette.dataPlot.lineWarn
		} else if (thresholds.length > 0 && mostRecentDataPoint?.value !== undefined) {
			if ((!isFinite(thresholdMin) || thresholdMin < mostRecentDataPoint.value) && (!isFinite(thresholdMax) || thresholdMax > mostRecentDataPoint.value)) {
				level = "green"
			} else {
				level = "red"
				lineColor = theme.palette.dataPlot.lineWarn
			}
		}
	}
	
	const lineGraph = true //dataPoint === DataPointType.O2 || dataPoint === DataPointType.Pulse

	const onDismissAlerts = (): void => {
		dismissAlerts(dataPoint)
	}

	let yRange: [number, number] | undefined = undefined
	if (dataPoint === DataPointType.Sleep) {
		yRange = [0, 24]
	}
	
	const metricProps = {
		currentValue,
		loading,
		dataPoint,
		mostRecentDataPoint,
	}
	
	return (
		<Box className={`${styles.cardRoot} ${level} ${isEnabled ? 'enabled' : 'disabled'}`}>
			{editThreshold && <ThresholdEditor {...thresholdEditorProps} />}
			<SnackbarError message="Failed to dismiss alerts" error={dismissAlertsStatus.error} />
			<Box className={styles.mainBorder} display="flex">
				
				<MetricDisplay {...metricProps} />
				
				<Box flex={1}>
					<Box display="flex" flexDirection="column" height="100%">
						<Box pl={0.5} height={"3.5rem"} display="flex">
							<Box mt={0.75} ml={0.25} flex={1} position="relative">
								<Typography style={{ lineHeight: "1.5rem", position:"absolute", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width:"100%"}} variant="body2" className={styles.avgLabel}>
									{currAvgValue} <DataPointLabel field={"unit"} dataPoint={dataPoint} /> (30d avg)
								</Typography>
							</Box>
							<Box ml={1} textAlign="right" pt={0.8}>
								{hasAlerts && <Tooltip title="Dismiss alert"><Button classes={buttonStyles} style={{width:"3rem", minWidth:"3rem", borderRadius:"0.4rem"}} disabled={dismissAlertsStatus.loading} onClick={onDismissAlerts}>OK</Button></Tooltip>}
							</Box>
							<Box color="text.primary">
								<IconButton edge="end" onClick={() => onShowFullHistory(dataPoint)} disabled={!isEnabled} color="inherit">
									<LaunchIcon />
								</IconButton>
							</Box>
							<Box color="text.primary" mr={1}>
								<IconButton edge="end" color="inherit" onClick={() => setEditThreshold(true)}>
									<SettingsOutlinedIcon />
								</IconButton>
							</Box>
						</Box>
						<Box flex={1} position="relative">
							<Box position="absolute" top={0} left={0} right={0} bottom={0}>
								{graphData && <DataPointGraph graphStyle={lineGraph ? "line" : "plot"} endDate={selectedInterval.intervalEnd} yRange={yRange} markerPoints={[]} lineColor={lineColor} thresholdMin={thresholdMin} thresholdMax={thresholdMax} xRange={getXRange(dataPoint)} graphData={graphData} />}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export type DoubleDataPointCardProps = DataPointCardProps & {
	secondaryDataPoint: DataPointType
}

export const DoubleDataPointCard: FC<DoubleDataPointCardProps> = ({ patient, dataPoint, secondaryDataPoint, selectedInterval, onShowFullHistory }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


	const styles = useAlertStyles()
	const theme = useTheme()
	const buttonStyles = useAlertButtonStyles()
	const [editThreshold, setEditThreshold] = useState<DataPointType | undefined>(undefined)
	const [dismissAlerts, dismissAlertsStatus] = useDismissAllAlertsForPatient(patient.id)
	const { graphData: primaryGraphData, error: primaryError, loading: primaryLoading } = useGetPatientGraphData(patient.id, selectedInterval.id, dataPoint, { startTime: selectedInterval?.intervalStart, endTime: selectedInterval?.intervalEnd })
	const { graphData: secondaryGraphData, error: secondaryError, loading: secondaryLoading } = useGetPatientGraphData(patient.id, selectedInterval.id, secondaryDataPoint, { startTime: selectedInterval?.intervalStart, endTime: selectedInterval?.intervalEnd })
	const error = primaryError || secondaryError
	const loading = primaryLoading || secondaryLoading
	const isEnabled = selectedInterval?.dataPoints?.indexOf(dataPoint) > -1


	const thresholds = sortBy(primaryGraphData?.thresholds || [], "createdOn").reverse()
	const secondaryThresholds = sortBy(secondaryGraphData?.thresholds || [], "createdOn").reverse()
	const alerts = sortBy((primaryGraphData?.alerts || []).concat(secondaryGraphData?.alerts || []).filter(a => !a.dismissed) || [], "startedOn").reverse()

	let { min: thresholdMin = -Infinity, max: thresholdMax = Infinity } = thresholds?.[0] || {}
	let { min: thresholdSecondaryMin = -Infinity, max: thresholdSecondaryMax = Infinity } = secondaryThresholds?.[0] || {}

	let metrics = selectedInterval?.dataPointMetrics?.find(dpm => dpm.dataPointType === dataPoint)
	let secondaryMetrics = selectedInterval?.dataPointMetrics?.find(dpm => dpm.dataPointType === secondaryDataPoint)

	///metrics?.average
	let mostRecentDataPoint = sortBy(primaryGraphData?.dataPoints.filter(i => i.value !== undefined) || [], "time").reverse()[0]
	let mostRecentSecondaryDataPoint = sortBy(secondaryGraphData?.dataPoints.filter(i => i.value !== undefined) || [], "time").reverse()[0]?.value
	
	let currentValue = ValueToStr(isEnabled, dataPoint, mostRecentDataPoint?.value, error)
	
//	let mostRecentSecondaryDataPoint = secondaryMetrics?.average //sortBy(allValidPoint, "time").reverse()[0]?.value
	let currentSecondaryValue = ValueToStr(isEnabled, dataPoint, mostRecentSecondaryDataPoint, error)
	

	let thresholdEditorProps = {
		currentMin: editThreshold === dataPoint ? thresholdMin : thresholdSecondaryMin,
		currentMax: editThreshold === dataPoint ? thresholdMax : thresholdSecondaryMax,
		patientId: patient.id,
		dataPointType: editThreshold!,
		onClose: () => setEditThreshold(undefined),
		open: !!editThreshold,
	}

	let level = ""

	let lineColor = theme.palette.dataPlot.line
	let hasAlerts = alerts.length > 0

	if (isEnabled) {
		if (hasAlerts) {
			level = "red"
			lineColor = theme.palette.dataPlot.lineWarn
		} else {
			if (thresholds.length > 0 && mostRecentDataPoint?.value !== undefined) {
				if ((!isFinite(thresholdMin) || thresholdMin < mostRecentDataPoint.value) && (!isFinite(thresholdMax) || thresholdMax > mostRecentDataPoint.value)) {
					level = "green"
				} else {
					level = "red"
					lineColor = theme.palette.dataPlot.lineWarn
				}
			}
			if (level === "green") {
				if (!!secondaryThresholds?.length && mostRecentSecondaryDataPoint !== undefined) {
					if ((!isFinite(thresholdSecondaryMin) || thresholdSecondaryMin < mostRecentSecondaryDataPoint) && (!isFinite(thresholdSecondaryMax) || thresholdSecondaryMax > mostRecentSecondaryDataPoint)) {
						level = "green"
					} else {
						level = "red"
						lineColor = theme.palette.dataPlot.lineWarn
					}
				}
			}
		}
	}

	const onDismissAlerts = (): void => {
		dismissAlerts(dataPoint)
		if (secondaryDataPoint) {
			dismissAlerts(secondaryDataPoint)
		}
	}

	let yRange: [number, number] | undefined = undefined
	if (dataPoint === DataPointType.Sleep) {
		yRange = [0, 24]
	}

	const handleClickSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}

	const handleCloseSettingsMenu = () => {
		setAnchorEl(null)
	}

	const onEditPrimaryThreshold = () => {
		handleCloseSettingsMenu()
		setEditThreshold(dataPoint)
	}

	const onEditSecondaryThreshold = () => {
		handleCloseSettingsMenu()
		setEditThreshold(secondaryDataPoint)
	}
	
	const metricProps = {
		currentValue:  `${currentValue}/${currentSecondaryValue}`,
		loading,
		dataPoint: DataPointType.BloodPressure,
		mostRecentDataPoint,
	}
	
	let currAvgValue = `${ValueToStr(isEnabled, dataPoint,  metrics?.average, error)}/${ValueToStr(isEnabled, dataPoint,  secondaryMetrics?.average, error)}`
	
	return (
		<Box className={`${styles.cardRoot} ${level} ${isEnabled ? 'enabled' : 'disabled'}`}>
			{editThreshold && <ThresholdEditor {...thresholdEditorProps} />}
			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseSettingsMenu}>
				<MenuItem onClick={onEditPrimaryThreshold}><DataPointLabel field={"label"} dataPoint={dataPoint} /></MenuItem>
				<MenuItem onClick={onEditSecondaryThreshold}><DataPointLabel field={"label"} dataPoint={secondaryDataPoint} /></MenuItem>
			</Menu>
			<SnackbarError message="Failed to dismiss alerts" error={dismissAlertsStatus.error} />
			<Box className={styles.mainBorder} display="flex">

				<MetricDisplay {...metricProps} />				
				
				<Box flex={1}>
					<Box display="flex" flexDirection="column" height="100%">
						<Box pl={0.5} height={"3.5rem"} display="flex">
							<Box mt={0.75} ml={0.25} flex={1} position="relative">
								<Typography style={{ lineHeight: "1.5rem", position:"absolute", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width:"100%"}} variant="body2" className={styles.avgLabel}>
									{currAvgValue} <DataPointLabel field={"unit"} dataPoint={dataPoint} /> (30d avg)
								</Typography>
							</Box>
							<Box ml={1} textAlign="right" pt={0.8}>
								{hasAlerts && <Tooltip title="Dismiss alert"><Button classes={buttonStyles} style={{width:"3rem", minWidth:"3rem", borderRadius:"0.4rem"}} disabled={dismissAlertsStatus.loading} onClick={onDismissAlerts}>OK</Button></Tooltip>}
							</Box>
							<Box color="text.primary">
								<IconButton edge="end" onClick={() => onShowFullHistory(dataPoint)} disabled={!isEnabled} color="inherit">
									<LaunchIcon />
								</IconButton>
							</Box>
							<Box color="text.primary" mr={1}>
								<IconButton edge="end" color="inherit" onClick={handleClickSettings}>
									<SettingsOutlinedIcon />
								</IconButton>
							</Box>
						</Box>
						<Box flex={1} position="relative">
							<Box position="absolute" top={0} left={0} right={0} bottom={0}>
								{primaryGraphData && secondaryGraphData && <DoubleDataPointGraph graphStyle="interpolation" endDate={selectedInterval.intervalEnd} yRange={yRange} markerPoints={[]} lineColor={lineColor} thresholdMin={thresholdMin} thresholdMax={thresholdMax} secondaryThresholdMin={thresholdSecondaryMin} secondaryThresholdMax={thresholdSecondaryMax} xRange={getXRange(dataPoint)} secondaryGraphData={secondaryGraphData} graphData={primaryGraphData} />}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default DataPointCard