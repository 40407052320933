import React, { FC, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import {startOfMonth} from 'date-fns'

import PageBanner from 'components/PageBanner'
import BillingList from './BillingList'
import { KeyboardDatePickerProps, KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DateTime, BillingFilter, useGetPracticeInfo } from 'store'


type DateRangePickerProps = {
	from?: DateTime
	to?: DateTime
	onChange: (from?: DateTime, to?: DateTime) => void
}

const DateRangePicker: FC<DateRangePickerProps> = ({ onChange, from, to }) => {
	const practiceQuery = useGetPracticeInfo()
	let isCustomInterval = practiceQuery?.practice?.customInterval

	const handleUpdateFrom = (date?: MaterialUiPickersDate) => {
		if (isCustomInterval) {
			onChange(date?.getTime(), to)
		} else {
			const newDate = !!date ? startOfMonth(date.getTime()).getTime() : undefined
			onChange(newDate, to)
		}
	}
	
	const handleUpdateTo = (date?: MaterialUiPickersDate) => {
		if (isCustomInterval) {
			onChange(from, date?.getTime())
		} else {
			const newDate = !!date ? startOfMonth(date.getTime()).getTime() : undefined
			onChange(from, newDate)
		}
	}

	let fromProps: KeyboardDatePickerProps = {
		value: from || null,
		placeholder: "From",
		onChange: handleUpdateFrom,
		format: isCustomInterval ? "MMM dd, yyyy" : "MMMM yyyy",
		variant: "inline",
		onMonthChange: (date)=> {
			if (isCustomInterval) {
				return
			}
			handleUpdateFrom(date)
		},
		openTo: isCustomInterval ? "date" : "month",
		views: isCustomInterval ? ["year", "month", "date"] : ["year", "month"],
	}

	let toProps: KeyboardDatePickerProps = {
		value: to || null,
		placeholder: "To",
		onChange: handleUpdateTo,
		onMonthChange: (date)=> {
			if (isCustomInterval) {
				return
			}
			handleUpdateTo(date)
		},
		format: isCustomInterval ? "MMM dd, yyyy" : "MMMM yyyy",
		variant: "inline",
		minDate: from,
		openTo: isCustomInterval ? "date" : "month",
		views: isCustomInterval ? ["year", "month", "date"] : ["year", "month"],
	}

	return (
		<Box mt={2} mb={1} mx={2} display="flex" alignItems="center">
			<Box mr={2}>
				<Typography variant="h3">Interval End:</Typography>
			</Box>
			<Box>
				<KeyboardDatePicker {...fromProps} />
			</Box>
			<Box mx={2} />
			<Box>
				<KeyboardDatePicker {...toProps} />
			</Box>
		</Box>

	)
}

const BillingView: FC = () => {
	const [filter, setFilter] = useState<BillingFilter>({})

	const onChagneDateFilter = (from?: DateTime, to?: DateTime) => {
		setFilter({ from, to })
	}

	return (
		<Box flex={1} display="flex" flexDirection="column">
			<PageBanner title="Billing" />
			<Box display="flex" flexDirection="column" flex={1}>
				<DateRangePicker from={filter.from} to={filter.to} onChange={onChagneDateFilter} />
				<BillingList filter={filter} />
			</Box>
		</Box>
	)
}

export default BillingView
