import React, { FC, useState, ChangeEvent, FormEvent, useEffect } from 'react'

import { Box, Button, Paper, TextField, TextFieldProps, InputProps, MenuItem, SelectProps, Select, FormControl, InputLabel, Typography } from '@material-ui/core'

import PageBanner from 'components/PageBanner'
import { IPatientInfo, Sex, useCreatePatient, PatientCreateInput } from 'store'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { KeyboardDatePickerProps, KeyboardDatePicker } from '@material-ui/pickers'
import { PhoneNumberTextField, EmailTextField, SnackbarError } from 'components'
import { Redirect } from 'react-router'
import { format } from 'date-fns'

const AddPatientView: FC = () => {

	const [formState, setFormState] = useState<PatientCreateInput>({ dob: "", email: "", firstName: "", lastName: "" })
	const [createPatient, createState] = useCreatePatient()
	const [formErrors, setFormErrors] = useState({ dob: false, email: false, firstName: false, lastName: false })
	const { called, loading, error, patientId } = createState
	const [success, setSuccess] = useState(false)

	/*	useEffect(() => {
			setFormState(patient || {})
		}, [inEditMode, patient])*/

	useEffect(() => {
		if (loading) {
			return
		}
		if (called && !error) {
			setSuccess(true)
		}
		return () => {
			setSuccess(false)
		}
	}, [called, loading, error])

	useEffect(() => {
		return () => {
			setSuccess(false)
		}
	}, [])


	const onUpdateStr = (key: keyof IPatientInfo) => {
		return (evt: ChangeEvent<{ value: string }>) => {
			setFormState({
				...formState,
				[key]: evt.target.value || ""
			})
		}
	}

	const maxLenTf = (len: number) => {
		return { inputProps: { maxLength: len } }
	}

	let txtFieldProps: TextFieldProps = {
		margin: "normal",
		fullWidth: true,
		...maxLenTf(100),
		disabled: loading,
	}

	let inputProps: InputProps = {
		margin: "dense",
		fullWidth: true,
		disabled: loading,
	}

	const handleUpdateDob = (date?: MaterialUiPickersDate) => {
		try {
			const dob = date ? format(date, "MM/dd/yyyy") : undefined
			setFormState({
				...formState,
				dob,
			})
		} catch (err) {
			// eat this, just an invalid date exception from "format"		
		}

	}

	let dopDatePickerProps: KeyboardDatePickerProps = {
		value: formState.dob ? new Date(formState.dob) : null,
		label: "Date of Birth*",
		fullWidth: true,
		margin: "normal",
		maxDate: new Date(Date.now()),
		onChange: handleUpdateDob,
		format: "MM/dd/yyyy",
		variant: "inline",
		disabled: loading,
		autoOk: true,
	}

	const sexProps: SelectProps = {
		label: "Sex",
		fullWidth: true,
		value: formState.sex || "",
		disabled: loading,
		onChange: (evt: ChangeEvent<{ value: unknown }>): void => {
			setFormState({
				...formState,
				sex: evt.target.value ? evt.target.value as Sex : Sex.NotSpecified,
			})
		}

	}

	const sexOptions = [
		<MenuItem key="ns" value={""}>Not Specified</MenuItem>,
		<MenuItem key="male" value={Sex.Male}>Male</MenuItem>,
		<MenuItem key="female" value={Sex.Female}>Female</MenuItem>,
	]

	const onSave = (evt: FormEvent) => {
		const errors: any[] = []
		formState.firstName = formState.firstName?.trim()
		formState.lastName = formState.lastName?.trim()

		evt.preventDefault()

		let regex = /[^@]+@[^@]+\.[^@]+/i
		if (!regex.test(formState.email)) {
			errors.push({ email: true })
		}

		if (!formState.dob?.trim()) {
			errors.push({ dob: true })
		}

		if (!formState.firstName) {
			errors.push({ firstName: true })
		}

		if (!formState.lastName) {
			errors.push({ lastName: true })
		}

		if (errors.length > 0) {
			setFormErrors(Object.assign({}, ...errors))
			return
		}

		setFormErrors({ dob: false, email: false, firstName: false, lastName: false })
		createPatient({ ...formState })
	}

	return (
		<Box flex={1}>
			{success && <Redirect to={`/patients/${patientId}`} />}
			<SnackbarError error={error} message="Failed to create patient" />
			<form onSubmit={onSave}>
				<PageBanner title="Add Patient" />
				<Box mx={4} my={5.5}>
					<Paper elevation={5}>
						<Box p={2} pb={1.5}>
							<Typography variant="h2">Patient Information</Typography>
						</Box>
						<Box display="flex" px={2}>
							<Box flex={1}>
								<TextField error={formErrors.firstName} {...txtFieldProps} onChange={onUpdateStr('firstName')} label={'First Name*'} />
							</Box>
							<Box width={"4.8rem"} />
							<Box flex={1}>
								<TextField error={formErrors.lastName} {...txtFieldProps} onChange={onUpdateStr('lastName')} label={'Last Name*'} />
							</Box>
						</Box>
						<Box display="flex" p={2}>
							<Box flex={1}>
								<KeyboardDatePicker error={formErrors.dob} {...dopDatePickerProps} />
							</Box>
							<Box width={"4.8rem"} />
							<Box flex={1}>
								<FormControl fullWidth margin="normal">
									<InputLabel id="sex-label">Sex</InputLabel>
									<Select labelId="sex-label"{...sexProps}>{sexOptions}
									</Select>
								</FormControl>
							</Box>
						</Box>
						<Box display="flex" p={2}>
							<Box flex={1}>
								<FormControl fullWidth margin="normal">
									<InputLabel htmlFor="phone">Phone Number</InputLabel>
									<PhoneNumberTextField id="phone" {...inputProps} value={formState.phone || ""} onChange={onUpdateStr("phone")} />
								</FormControl>
							</Box>
							<Box width={"4.8rem"} />
							<Box flex={1}>
								<FormControl fullWidth margin="normal">
									<InputLabel htmlFor="email">Email*</InputLabel>
									<EmailTextField error={formErrors.email} placeholder={"jane.smith@murj.com"} id="email" {...inputProps} value={formState.email || ""} onChange={onUpdateStr("email")} />
								</FormControl>
							</Box>
						</Box>
						<Box px={2} pt={2.5} pb={3.5} display="flex" justifyContent="flex-end">
							<Button disabled={loading} variant="contained" color="primary" type="submit">Create patient</Button>
						</Box>
					</Paper>
				</Box>
			</form>
		</Box>
	)
}

export default AddPatientView
