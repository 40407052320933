import React, { FC, useState, MouseEvent, ChangeEvent } from 'react'

import { sortBy as doSortBy } from 'underscore'
import { Box, TableContainer, Table, TableHead, TableRow, TableSortLabel, TableCell, TableBody, TablePagination, Typography, Button, makeStyles } from '@material-ui/core'
import { FormattedDate } from 'react-intl'
import { Link as RoutedLink, useParams } from 'react-router-dom'

import { useGetPatientInfo, useGetPracticeInfo } from 'store'
import { DataPointIconList } from 'components'


const useStyles = makeStyles((theme)=> ({
	hasAlerts: {
		color: theme.palette.alertRed
	},
	noAlerts: {
		color: theme.palette.alertGreen
	}
}))

enum IntervalSortBy {
	Period,
	DaysOfData,
	Alerts,
	SignedBy,
	DataPoints
}

const HistoryList: FC = () => {
	const { patientId } = useParams<{ patientId: string }>()
	const { patient } = useGetPatientInfo(patientId)
	const [sortOrder, setSortOrder,] = useState<'asc' | 'desc'>('desc')
	const [sortBy, setSortBy,] = useState<IntervalSortBy>(IntervalSortBy.Period)
	const practiceInfo = useGetPracticeInfo()
	const isCustomInterval = !!practiceInfo.practice?.customInterval
	
	const [pageIndex, setPageIndex,] = useState<number>(0)
	const [rowsPerPage, setRowsPerPage,] = useState<number>(10)
	const styles = useStyles()
	//const { loading, reports } = { loading: false, error: undefined, reports: [] }

	const setSort = (val: IntervalSortBy) => {
		if (sortBy === val) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortBy(val)
		}
	}

	const updateSortFn = (type: IntervalSortBy) => {
		return () => {
			setSort(type)
		}
	}

	const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
		setPageIndex(page)
	}

	const reportOrder = patient?.intervals?.map(a => a.id).reverse() || []

	let sortedIntervals = doSortBy(patient?.intervals || [], (interval) => {
		switch (sortBy) {
			case IntervalSortBy.DataPoints:
				return interval.dataPoints.length
			case IntervalSortBy.SignedBy:
				return interval.signoff[0]?.practitioner?.name || ""
			case IntervalSortBy.DaysOfData:
				return interval.daysOfData
			case IntervalSortBy.Alerts:
				return interval.alerts.length
			case IntervalSortBy.Period:
				return interval.intervalEnd
			default:
				return reportOrder.indexOf(interval.id)
		}
	})

	if (sortOrder === "desc") {
		sortedIntervals = sortedIntervals?.reverse()
	}
	

	const rowEls = sortedIntervals?.map((interval) => {
		const approval = interval.signoff.find(s => !!s.approvedOn)
		const approver = approval?.practitioner?.name
		const approvedOn = approval?.approvedOn
		
		let dateDisplay
		if (isCustomInterval) {
			dateDisplay = <>{!!interval.intervalStart && <FormattedDate value={interval.intervalStart} month="short" day="numeric" />} - {!!interval.intervalEnd && <FormattedDate value={interval.intervalEnd} month="short" day="numeric" year="numeric" />}</>
		} else {
			dateDisplay = <>{!!interval.intervalEnd && <FormattedDate value={interval.intervalEnd - 86400000} month="long" year="numeric" />}</>
		}
		
		return (
			<TableRow key={interval.id}>

				<TableCell align={'left'}>
					<Box mb={0.5}>
						<Typography variant={"h2"}>
							{dateDisplay}
						</Typography>
					</Box>
				</TableCell>

				<TableCell align={'left'}>
					{interval.daysOfData} Days
				</TableCell>

				<TableCell align={'left'} className={interval.alerts.length > 0 ? styles.hasAlerts : styles.noAlerts}>
					{interval.alerts.length}
				</TableCell>

				<TableCell align={'left'}>
					{approver || ""}
					<Typography variant="body1" component="div">
						{!!approvedOn && <FormattedDate value={approvedOn} day="numeric" month="numeric" year="numeric" />}
					</Typography>
				</TableCell>

				<TableCell align={'center'}>
						<DataPointIconList thresholdedDataPoints={interval.dataPointMetrics.filter(d => d.hasThresholds).map(d => d.dataPointType)} alertDataPoints={interval.dataPointMetrics.filter(d => d.hasAlerts).map(d => d.dataPointType)} />
				</TableCell>
				
				<TableCell align={'center'}>
						<Button component={RoutedLink} to={`/patients/${patientId}/${interval.id}`} variant="contained" color="primary">View</Button>
				</TableCell>

			</TableRow>
		)
	})

	const emptyRows = rowsPerPage - (sortedIntervals || []).length


	return (
		<Box flex={1} display="flex" flexDirection="column">
			<Box flex={1} position="relative" mx={2} my={3}>
				<TableContainer style={{ position: "absolute", maxHeight: "100%", height: "100%", width: "100%" }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell align={'left'}>
									<TableSortLabel color="inherit" active={sortBy === IntervalSortBy.Period} direction={sortOrder} onClick={updateSortFn(IntervalSortBy.Period)}>
										Interval
									</TableSortLabel>
								</TableCell>
								<TableCell align={'left'}>
									<TableSortLabel color="inherit" active={sortBy === IntervalSortBy.DaysOfData} direction={sortOrder} onClick={updateSortFn(IntervalSortBy.DaysOfData)}>
										Days of Data
										</TableSortLabel>
								</TableCell>
								<TableCell align={'left'}>
									<TableSortLabel color="inherit" active={sortBy === IntervalSortBy.Alerts} direction={sortOrder} onClick={updateSortFn(IntervalSortBy.Alerts)}>
										Alerts
										</TableSortLabel>
								</TableCell>
								<TableCell align={'left'}>
									<TableSortLabel color="inherit" active={sortBy === IntervalSortBy.SignedBy} direction={sortOrder} onClick={updateSortFn(IntervalSortBy.SignedBy)}>
										Signed By
										</TableSortLabel>
								</TableCell>
								<TableCell align={'center'}>
									<TableSortLabel color="inherit" active={sortBy === IntervalSortBy.DataPoints} direction={sortOrder} onClick={updateSortFn(IntervalSortBy.DataPoints)}>
										Data Points
										</TableSortLabel>
								</TableCell>
								<TableCell align={'center'}>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rowEls}
							{emptyRows > 0 && (
								<TableRow>
									<TableCell colSpan={8} style={{ height: 53 * emptyRows, }}>
										<Box display="flex" justifyContent="center" alignItems="center">
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			<TablePagination component="div" colSpan={8} count={patient?.intervals?.length || 0} rowsPerPageOptions={[100]} onChangeRowsPerPage={(evt: ChangeEvent<HTMLInputElement>) => setRowsPerPage(parseInt(evt.target.value, 10))} rowsPerPage={rowsPerPage} page={pageIndex} onChangePage={handleChangePage} />
		</Box>

	)
}

export default HistoryList