import React, { FC } from 'react'


export const getSymptomString = (symptomEnumVal: string): string => {
	switch (symptomEnumVal) {
		case "none":
			return "None Reported"
		case "HKCategoryTypeIdentifierDizziness":
			return "Dizziness"
		case "HKCategoryTypeIdentifierRapidPoundingOrFlutteringHeartbeat":
			return "Rapid, pounding, or fluttering heartbeat"
		case "HKCategoryTypeIdentifierSkippedHeartbeat":
			return "Skipped heartbeat"
		case "HKCategoryTypeIdentifierFatigue":
			return "Fatigue"
		case "HKCategoryTypeIdentifierShortnessOfBreath":
			return "Shortness of breath"
		case "HKCategoryTypeIdentifierChestTightnessOrPain":
			return "Chest tightness or pain"
		case "HKCategoryTypeIdentifierFainting":
			return "Fainting"
		default:
			return (symptomEnumVal || "").replace("HKCategoryTypeIdentifier", "")
	}
}

export const EcgSymptomList: FC<{ limited?: boolean; symptoms?: Array<string> }> = ({ limited, symptoms }) => {
	let str
	if (limited && symptoms && symptoms[0]) {
		if (symptoms.length > 1) {
			str = `${getSymptomString(symptoms[0])} + ${symptoms.length - 1} more`
		} else {
			str = `${getSymptomString(symptoms[0])}`
		}
	} else {
		str = symptoms?.map(getSymptomString)?.join("; ")
	}
	return <>{str}</>

}

export default EcgSymptomList

