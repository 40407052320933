import React, { FC } from 'react'

import { useTheme } from '@material-ui/core'

const GraphAlertMarker: FC<any> = (props) => {
	const muiTheme = useTheme()
	const { x, y, datum } = props;

	let size = { w: 13, h: 14 }
	if (props.size === "large") {
		size = { w: 27, h: 40 }
	}

	if (datum.pointUp) {
		return (
			<g >
				<svg x={x - size.w / 2} y={y + size.h / 4} width={size.w} height={size.h} viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path transform="scale(1,-1) translate(0, -10)" d="M4.32206 9.50244L8.06507 0.478518L0.579046 0.478518L4.32206 9.50244Z" fill={muiTheme.palette.alertRed} />
				</svg>
			</g>
		);
	} else {
		return (
			<g>
				<svg x={x - size.w / 2} y={y - size.h} width={size.w} height={size.h} viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path  d="M4.32206 9.50244L8.06507 0.478518L0.579046 0.478518L4.32206 9.50244Z" fill={muiTheme.palette.alertRed} />
				</svg>
			</g>
		);
	}

}

export default GraphAlertMarker