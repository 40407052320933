import React, { FC, ChangeEvent } from 'react'

import { Box, FormControlLabel, Checkbox, Button, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router'

import { useGetPatientInfo, useGetPracticeInfo, useSignoffInterval } from 'store'
import { SnackbarError } from 'components'
import { format } from 'utils/date'


const useSignoffStyles = makeStyles((theme) => ({
	button: {
		background: theme.palette.alertGreen
	}
}))

const SignoffView: FC = () => {
	const styles = useSignoffStyles()

	const { patientId, intervalId } = useParams<{ patientId: string, intervalId: string }>()
	const { patient } = useGetPatientInfo(patientId)
	const [doSignoff, signoffStatus] = useSignoffInterval(intervalId)
	const practiceInfo = useGetPracticeInfo()

	let selectedInterval = patient?.intervals.find(i => i.id === intervalId) || patient?.intervals[0]

	let is99457 = selectedInterval?.signoff.find(a => !!a.cpt_99457)
	let is99458 = selectedInterval?.signoff.find(a => !!a.cpt_99458)
	let is99458b = selectedInterval?.signoff.find(a => !!a.cpt_99458_2)
	let is99454 = selectedInterval?.signoff.find(a => !!a.cpt_99454)

	let approval = selectedInterval?.signoff.find(a => !!a.approvedOn)

	const onCheckOption1 = (evt: ChangeEvent<HTMLInputElement>) => {
		doSignoff({ cpt_99457: evt.target.checked })
	}
	const onCheckOption2 = (evt: ChangeEvent<HTMLInputElement>) => {
		doSignoff({ cpt_99458: evt.target.checked })
	}
	const onCheckOption2b = (evt: ChangeEvent<HTMLInputElement>) => {
		doSignoff({ cpt_99458_2: evt.target.checked })
	}
	
	const onCheckOption3 = (evt: ChangeEvent<HTMLInputElement>) => {
		doSignoff({ cpt_99454: evt.target.checked })
	}

	const onApprove = () => {
		doSignoff({ approved: true })
	}

	// RPM-95 changes how approval is admissable
	// For 99454: data readings (daysOfData) is required
	// For 99457: data readings are not required
	const canApprove = ((!!is99454 && !!selectedInterval?.daysOfData) || !!is99457) && !!selectedInterval?.internalNote && selectedInterval
	
	const customIntervals = !!practiceInfo.practice?.customInterval
	return (
		<Box mx={2} mt={2} mb={1} display="flex" className="printHidden">
			<SnackbarError message="Failed to update signoff" error={signoffStatus.error} />
			<Box flex={1}>
				<Box style={{height:customIntervals ? "3rem" : "auto"}}>
					<FormControlLabel
						control={<Checkbox disabled={!!approval || signoffStatus.loading || !!is99458} size="small" checked={!!is99457} onChange={onCheckOption1} name="99457" />}
						label="I acknowledge that I have read for at least 20 minutes."
					/>
				</Box>
				<Box style={{height:customIntervals ? "3rem" : "auto", whiteSpace: "nowrap"}}>
					<FormControlLabel
						control={<Checkbox  disabled={!!approval || !is99457 || signoffStatus.loading || !!is99458b} size="small" checked={!!is99458} onChange={onCheckOption2} name="99458" />}
						label="I acknowledge that I have read for an additional 20 minutes."
					/>
					<FormControlLabel
						control={<Checkbox  disabled={!!approval || !is99458 || signoffStatus.loading} size="small" checked={!!is99458b} onChange={onCheckOption2b} name="99458_2" />}
						label="Second additional 20 minutes."
					/>
				</Box>
				{customIntervals && 
				<Box style={{height:"3rem"}}>
					<FormControlLabel
						control={<Checkbox disabled={!!approval || signoffStatus.loading} size="small" checked={!!is99454} onChange={onCheckOption3} name="99454" />}
						label={`Device supplied by clinic. Received at least 16 days of data. (Current count ${selectedInterval?.daysOfData})`}
					/>
				</Box>}
			</Box>
			<Box>
				{approval && `Approved by: ${approval.practitioner.name}`}
				{approval?.approvedOn &&  <Box>{format(new Date(approval.approvedOn || 0), "P p")}</Box>}
				{!approval && <Button className={styles.button} onClick={onApprove} disabled={!canApprove || signoffStatus.loading} color="secondary" variant="contained">Approve</Button>}
			</Box>
		</Box>
	)
}

export default SignoffView