import React, { FC } from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme)=> ({
	root: {
		height: '9rem',
		color: theme.palette.pageBannerText,
		backgroundColor: theme.palette.pageBannerBg,
		borderBottom: `solid 0.1rem ${theme.palette.borderColor}`,
		display:'flex',
		alignItems:'center',
		padding:'0 3rem 0 0',
	},
}))

const PageBanner: FC<{title?:string, className?:string}> = ({className, title, children}) => {
	const styles = useStyles()
	return (
		<Box className={`${className || ""} ${styles.root}`} >
			{title && <Typography variant="h1">{title}</Typography>}
			{children}
		</Box>
	)
}

export default PageBanner
