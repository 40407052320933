import React, { FC, } from 'react'

import { Drawer, makeStyles, ListItem, List, SvgIconTypeMap } from '@material-ui/core'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined'
import { Link as RoutedLink, matchPath, useLocation } from 'react-router-dom'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

const useDrawerStyles = makeStyles((theme) => ({
	root: {
		width: "7.2rem",
		zIndex: 98,
		background: "#FFF",
		borderRight: "solid 0.1rem #A8A8A8"
	},
	toolbar: {
		width: "7.2rem",
	},
	toolbarList: {
		padding: 0
	},
	toolbarSpacer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		minHeight: "9.2rem"
	},
	toolbarItem: {
		color: theme.palette.primary.main,
		height: "6.2rem",
		fontSize: "3rem",
		textAlign: "center",
		"&.Mui-selected": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			cursor:"default",
			"&:hover": {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
			},
			"&:active": {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
			}
		},
		"& svg": {
			margin: "auto",
		},
	}

}))

const ToolbarItem: FC<{route:string, title:string, Icon:OverridableComponent<SvgIconTypeMap<{}, "svg">>}> = ({route, title, Icon}) => {
	const styles = useDrawerStyles()
	const location = useLocation()
	let selected = !!matchPath(location.pathname, { path: route })
	
	let props = {
		className:styles.toolbarItem,
		component:RoutedLink,
		to:route,
		selected:selected,
	}
	
	return ( <ListItem {...props} button>
			<Icon fontSize="inherit" color="inherit" titleAccess={title} />
		</ListItem>
	)
}

const LeftToolbar: FC = () => {
	const styles = useDrawerStyles()

	return (
		<Drawer variant="permanent" className={styles.root} id="leftToolbar">
			<div className={styles.toolbarSpacer} />
			<div className={styles.toolbar}>
				<List className={styles.toolbarList}>
					<ToolbarItem route="/dashboard" title="Dashboard View" Icon={HomeOutlinedIcon}/>
					<ToolbarItem route="/patients" title="Patients View" Icon={PersonOutlineOutlinedIcon}/>
					<ToolbarItem route="/billing" title="Billing View" Icon={UpdateOutlinedIcon}/>
					{/*<ToolbarItem route="/devices" title="Transmission Logs View" Icon={MobileScreenShareOutlinedIcon}/>*/}
				</List>
			</div>
		</Drawer>
	)
}

export default LeftToolbar
