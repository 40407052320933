import React, { FC, useState, FocusEvent, useRef, MouseEvent } from 'react'

import { Input, InputProps } from '@material-ui/core'
import MaskedInput from 'react-text-mask'


interface TextMaskCustomProps {
	inputRef: (ref: HTMLInputElement | null) => void;
	showMask?: boolean
}

const TextMaskCustom: FC<TextMaskCustomProps> = (props) => {
	const { inputRef, showMask, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref: any) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			showMask={showMask}
		/>
	);
}



const PhoneNumberTextField: FC<InputProps> = (props) => {
	const [showMask, setShowMask] = useState<boolean | undefined>(undefined)
	const iput = useRef<HTMLInputElement | null>(null)
	const onFocus = (evt: FocusEvent<HTMLInputElement>) => {
		setShowMask(true)
		iput.current?.setSelectionRange(1, 1)
	}

	const onClick = (evt: MouseEvent) => {
		evt.preventDefault()
		setShowMask(true)
		setTimeout(() => {
			iput.current?.setSelectionRange(1, 1)
		}, 1)
	}

	return (
		<Input onClick={onClick} onFocus={onFocus} onBlur={() => setShowMask(false)} {...props} inputRef={iput} inputProps={{ showMask }} inputComponent={TextMaskCustom as any} />
	)
}

export default PhoneNumberTextField
