import {
  Palette,
  PaletteOptions
} from '@material-ui/core/styles/createPalette'

import { Theme } from '@material-ui/core/styles'
import { ThemeOptions, createMuiTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { useMemo } from 'react'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    palette: Palette;
    headerHeight: any;
  }
  interface ThemeOptions {
    palette?: PaletteOptions;
    headerHeight?: any;
  }
}


declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    borderColor: string;
    pageBannerText: string;
    pageBannerBg: string;
    alertRed: string;
    alertGreen: string;
    alertInactive: string;
    alertNominal: string;
    alertHeartStroke: string;
    dataPlot: {
      lineWarn: string;
      line: string;
      thresholdStroke: string
      gridLineStroke: string
    }
  }

  interface PaletteOptions {
    borderColor: string;
    pageBannerText: string;
    pageBannerBg: string;
    alertRed: string;
    alertGreen: string;
    alertInactive: string;
    alertNominal: string;
    alertHeartStroke: string;
    dataPlot: {
      lineWarn: string;
      line: string;
      thresholdStroke: string
      gridLineStroke: string
    }

  }
}


const createMyTheme = (options: ThemeOptions): Theme => {
  const theme = createMuiTheme({
    ...options
  })
  return theme
}



const borderColor = (darkMode: boolean): string => {
  return darkMode ? "#5d5d5d" : "#ccc"
}


const createTheme = (darkMode: boolean) => {
  return createMyTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      background: {
        paper: darkMode ? "#212020" : "#FFF",
        default: darkMode ? "#212020" : "#fafafa",
      },

      dataPlot: {
        lineWarn: "#FD961C",
        line: "#13BFF4",
        thresholdStroke: "#c43a31",
        gridLineStroke: "#ECEFF1",

      },
      action: {
        selected: "#13BFF4",
        hover: "#13BFF4",
      },
      grey: {
        500: "#959292"
      },
      alertHeartStroke: darkMode ? "#eee" : "#aaa",
      alertInactive: darkMode ? "#626262" : "#D4D4D4",
      alertNominal: darkMode ? "#D4D4D4" : "#626262",
      alertRed: darkMode ? "#FF0000" : "#FF0000",
      alertGreen: darkMode ? "#48D524" : "#48D524",
      borderColor: borderColor(darkMode),
      pageBannerText: darkMode ? "#dad8d8" : "#4B4B4B",
      pageBannerBg: darkMode ? "#3a3a3a" : "#eee",
      primary: {
        main: "#13BFF4",
        contrastText: '#FFF',
      },
      secondary: {
        main: '#FD961C',
        contrastText: '#FFF',
      },
      text: {
        disabled: darkMode ? "#525252" : "#b5b4b4",
        primary: darkMode ? "#dad8d8" : "#31465f",
        secondary: darkMode ? "#a7a5a5" : '#6b6b6b',
      },
    },
    spacing: factor => `${factor}rem`,
    typography: {
      fontFamily: 'Roboto',
      fontSize: 16,
      htmlFontSize: 16,
      h1: {
        fontSize: '3.8rem',
        fontWeight: 100,

        /** compliance: https://app.clubhouse.io/murj/story/433/clinician-app-update-colors-in-clinician-app */
        lineHeight: 1.167,
        height: "9rem",
        display: "flex",
        padding: "0 3rem",
        alignItems: "center",
        borderBottom: "solid 0.1rem #13BFF4",
        backgroundColor: darkMode ? "#333" : "#eee",

      },
      h2: {
        fontSize: '3.6rem',
        fontWeight: 400,
        textTransform: 'none',
      },
      h3: {
        fontSize: '2.4rem',
        fontWeight: 400,
        textTransform: 'none',
      },
      h4: {
        fontSize: '1.8rem',
        fontWeight: 900,
        textTransform: 'none',
      },
      h5: {
        fontSize: '1.6rem',
        fontWeight: 900,
        textTransform: 'none',
      },
      h6: {
        fontSize: '2.2rem',
        fontWeight: 700,
        textTransform: 'none',
      },
      subtitle1: {
        fontSize: '2rem',
        fontWeight: 500,
        textTransform: 'none',
      },
      subtitle2: {
        fontSize: '1.6rem',
        fontWeight: 400,
        textTransform: 'none',
      },
      body1: {
        fontSize: '1.8rem',
        fontWeight: 400,
        textTransform: 'none',
      },
      body2: {
        fontSize: '1.4rem',
        fontWeight: 400,
        textTransform: 'none',
      },
      button: {
        fontSize: '1.6rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        letterSpacing: 1.25,
      },
      caption: {
        fontSize: '1.4rem',
        fontWeight: 400,
        textTransform: 'none',
        letterSpacing: 1.25,
      },
      overline: {
        fontSize: '1.4rem',
        fontWeight: 400,
        textTransform: 'uppercase'
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          zIndex: 99,
          position: "relative",
          height: "9.2rem",
          justifyContent: "center",
          borderBottom: `0.1rem solid ${borderColor(darkMode)}`,
        },
        colorDefault: {
          backgroundColor: darkMode ? "#212020" : "#FFF",
        }
      },
      MuiButton: {
        contained: {
          borderRadius: "0.6rem",
        },
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: "#13BFF4"
        }
      },
      MuiTableCell: {
        root: {
          fontSize: "2.4rem"
        },
        head: {
          fontSize: "1.4rem",
          color: "#A8A8A8",
        }
      },
      MuiTableSortLabel: {
        root: {
          fontSize: "1.4rem",
          color: "#A8A8A8 !important",
        }
      }

    },
  })
}

export const useTheme = () => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') && process.env.NODE_ENV === "development"

  const theme = useMemo(() => {
    return createTheme(prefersDarkMode)
  }, [prefersDarkMode])

  return theme

}
