import React, { FC, useEffect, useState, useRef, MutableRefObject } from 'react'

import { min, max, isFinite } from 'underscore'
import { VictoryTheme, VictoryAxis, VictoryChart, VictoryLine, VictoryLabel, VictoryChartProps, VictoryLabelProps, VictoryLineProps, VictoryScatter, VictoryScatterProps, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import { useTheme } from '@material-ui/core'

import { format } from 'utils/date'
import { GraphAlertMarker, GetDataPointString, GraphPointMarker } from 'components'
import { DataPointType } from 'store';

const useDimensions = (): [MutableRefObject<HTMLDivElement>, { width?: number, height?: number }] => {
	const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
	const [dimensions, setDimensions] = useState<{ width?: number, height?: number }>({
		width: undefined,
		height: undefined,
	})

	useEffect(() => {
		let handleResize = () => {
			if (ref.current) {
				setDimensions(ref.current.getBoundingClientRect().toJSON())
			}
		}

		window.addEventListener("resize", handleResize);
		handleResize()
		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, [ref])

	return [ref, dimensions]
}


export type AlertCardGraphProps = {
	data: Array<{ time: number, value: number }>
	markers: Array<{ time: number, value: number }>
	thresholdMin?: number
	thresholdMax?: number
	dataPoint: DataPointType
}


const AlertCardGraph: FC<AlertCardGraphProps> = ({ dataPoint, markers, data, thresholdMin, thresholdMax }) => {
	const [ref, dimensions] = useDimensions()
	const muiTheme = useTheme()

	const thresholdRed = muiTheme.palette.dataPlot.thresholdStroke
	let thresholdStyle = {
		data: { stroke: thresholdRed, strokeWidth: 1.0 },
	}

	let lineStyle = {
		data: { stroke: muiTheme.palette.dataPlot.lineWarn, strokeWidth: 1.25 },
	}

	let theme = {
		...VictoryTheme.material,
		axis: {
			...VictoryTheme.material.axis,
			"style": {
				...VictoryTheme.material.axis?.style,
				axis: {
					stroke: "transparent"
				},
				ticks: {
					stroke: "transparent"
				},
				grid: {
					...VictoryTheme.material.axis?.style?.grid,
					"stroke": muiTheme.palette.dataPlot.gridLineStroke,
					"strokeWidth": 1,
					"strokeDasharray": "1, 1",
				},
			}
		}
	}

	if (!!data?.length && data.length < 2) {
		data[1] = {
			time: data[0].time + 1000,
			value: data[0].value,
		}
	}

	let times = data?.map(d => d.time)
	let minPoint = 0
	let maxPoint = 1

	if (times.length > 0) {
		minPoint = min(times)
		maxPoint = max(times)
	}

	let values = data?.map(d => d.value)
	let minVal = 0
	let maxVal = 1

	if (values.length > 0) {
		minVal = min(values)
		maxVal = max(values)
	}


	if (minVal === maxVal) {
		if (dataPoint === DataPointType.StepCounter) {
			maxVal = minVal + 100
			minVal = Math.max(0, minVal - 100)
		} else {
			maxVal = minVal + 100
		}
	}

	let chartProps: VictoryChartProps = {
		theme,
		domain: { x: [minPoint, maxPoint], y: [minVal, maxVal] },
		domainPadding: { y: 20 },
		width: dimensions.width || 260,
		height: dimensions.height || 50,
		padding: { top: 5, bottom: 5, right: 5, left: 45 },
		containerComponent: <VictoryVoronoiContainer preserveAspectRatio="none" responsive={true} />,
		/*		containerComponent: <VictoryContainer preserveAspectRatio="none" responsive={true} />*/
	}

	const TickLabel: FC<VictoryLabelProps> = (props) => {
		if (props.text === `${thresholdMin}` || props.text === `${thresholdMax}`) {
			return <VictoryLabel {...props} style={[{ fill: thresholdRed }]} />
		}
		return <VictoryLabel {...props} />
	}


	let isBloodPressure = dataPoint === DataPointType.BloodPressure || dataPoint === DataPointType.Systolic || dataPoint === DataPointType.Diastolic

	let lineProps: VictoryLineProps = {
		style: lineStyle,
		data,
		x: "time",
		y: "value",
		interpolation: isBloodPressure ? "monotoneX" : undefined
	}
	
	let bpScatter
	if (isBloodPressure) {
		let lProps: VictoryScatterProps = {
			style: lineStyle,
			data,
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={lineStyle.data.stroke} />
		}
		bpScatter = <VictoryScatter  {...lProps} />
	}

	let tMin = thresholdMin === undefined ? -Infinity : thresholdMin
	//let tMax = thresholdMax === undefined ? Infinity : thresholdMax

	let markersProps: VictoryScatterProps = {
		data: markers.map(m => {
			return {
				...m,
				pointUp: (m.value <= tMin)
			}
		}),
		x: "time",
		y: "value",
		labels: ({ datum }) => `${format(datum.time, "M/d/yy hh:mma")}\n${GetDataPointString(dataPoint, "unit", datum.value)}`,
		labelComponent: <VictoryTooltip />,
		dataComponent: <GraphAlertMarker />
	}


	return (
		<div style={{ height: "6rem" }} ref={ref} >
			<VictoryChart {...chartProps}>

				<VictoryAxis dependentAxis tickCount={3} tickLabelComponent={<TickLabel />} />

				{isFinite(thresholdMin) && <VictoryLine style={thresholdStyle} y={() => thresholdMin} samples={1} />}
				{isFinite(thresholdMax) && <VictoryLine style={thresholdStyle} y={() => thresholdMax} samples={1} />}
				{bpScatter}
				<VictoryLine {...lineProps} />
				<VictoryScatter {...markersProps} />

			</VictoryChart>
		</div >
	)
}

export default AlertCardGraph