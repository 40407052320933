import React, { FC } from 'react'

import { Box, Typography, CircularProgress } from '@material-ui/core'

export const ColHeader: FC<{title:string, loading:boolean}> = ({title, loading}) =>{
				

	return <Box pt={1.5} pb={5} height="5rem"><Typography variant="h2">{title} {loading && <CircularProgress size={17} />}</Typography></Box>
}

export default ColHeader