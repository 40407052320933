import React, { FC, ChangeEvent } from 'react'

import { Box, MenuItem, Select, SelectProps, Typography, makeStyles } from '@material-ui/core'

import { format } from 'utils/date'
import { IPatientInfo, useGetPracticeInfo } from 'store'
import { useHistory, useParams } from 'react-router'

const useSelectStyles = makeStyles((_theme) => ({
	root: {
		fontSize: "3.6rem",
		lineHeight: "3.6rem",
	}
}))


export interface IIntervalSelector {
	patient?: IPatientInfo,
}

const IntervalSelector: FC<IIntervalSelector> = ({ patient }) => {
	const { patientId, intervalId } = useParams<{ patientId: string, intervalId?: string }>()
	const selectClasses = useSelectStyles()

	const history = useHistory()
	const practiceInfo = useGetPracticeInfo()

	let intervalOptions =  <MenuItem  value={patient?.activeInterval?.id}>CURRENT</MenuItem> 
	//const historySelected = !!intervalId && intervalId !== patient?.activeInterval?.id //"history"
	let selectedInterval = patient?.intervals.find(i => i.id === intervalId) || patient?.intervals[0]

	let val = intervalId || ""
//	if (patient?.activeInterval?.id && patient?.activeInterval?.id !== intervalId) {
//		val = ""
//	}
	const selectOpts: SelectProps = {
		classes: selectClasses,
		fullWidth: false,
		value: val,
		renderValue: (v)=> {
			return v === patient?.activeInterval?.id ? "Current" : "History"
		},
		onChange: (evt: ChangeEvent<{ value: unknown }>): void => {
			history.push(`/patients/${patientId}/${evt.target.value}`)
		}
	}
	
	let dateDisplay
	if (!!intervalId && intervalId !== 'history' && !!selectedInterval) {
		if (!!practiceInfo.practice?.customInterval) {
			dateDisplay = (<Box>
				<Typography variant="h2">
					Monitoring Period: {selectedInterval.intervalStart ? format(selectedInterval.intervalStart, "MMM d") : ""} - {selectedInterval.intervalEnd ? format(selectedInterval.intervalEnd, "MMM d, yyyy") : ""}
				</Typography>
			</Box>)
			
		} else {
			dateDisplay = (<Box>
				<Typography variant="h2">
					Monitoring Period: {selectedInterval.intervalEnd ? format(selectedInterval.intervalEnd - 86400000, "MMMM yyyy") : ""}
				</Typography>
			</Box>)
		}
	}

	return (
		<Box display="flex" color={"text.primary"} width="100%" height="100%" justifyContent="center" alignItems="center">
			<Box flex={1} mr={2} ml={3}>
				<Select {...selectOpts}>
					{intervalOptions}
					<MenuItem value={"history"}>HISTORY</MenuItem>
				</Select>
			</Box>

			{dateDisplay}
		</Box>
	)
}

export default IntervalSelector
