import React, { FC, useState, useEffect, MouseEvent, ChangeEvent, useCallback } from 'react'
import _ from 'underscore'
import { Box, Link, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel, CircularProgress, TablePagination, MenuItem, Select, makeStyles, LinearProgress } from '@material-ui/core'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import { FormattedDate } from 'react-intl'
import { differenceInCalendarDays, formatDistanceStrict } from 'date-fns'
import { Link as RoutedLink, useHistory } from 'react-router-dom'

import PageBanner from 'components/PageBanner'
import { IPatientListItem, useGetPatientsList, PatientSortType, SortOrder, IPatientListFilter, DataPointType, PatientListFilterAlertType, useSetPatientWatched, useGetIntervalDaysOfData, useGetPracticeInfo } from 'store'
import DataPointIconList from 'components/DataPointIconList'
import DataPointSelect, { DataPointSelectProps } from 'components/DataPointSelect'
import { ErrorBox } from 'components'


const Filters: FC<{ filter: IPatientListFilter, onChangeFilter: (val: IPatientListFilter) => void }> = ({ filter, onChangeFilter }) => {
	let dataPoints: Array<DataPointType> = filter.dataPoints || []
	const dataPointsProps: DataPointSelectProps = {
		id: "dataPointFilter",
		fullWidth: false,
		displayEmpty: true,
		multiple: true,
		/*label: "Filter",*/
		value: dataPoints,
		onChange: (value: Array<DataPointType> | DataPointType) => {
			let dataPoints = value as Array<DataPointType>
			onChangeFilter({ ...filter, dataPoints })
		},
	}

	const alertProps = {
		label: "Filter",
		value: filter.alertStatus || "",
		onChange: (evt: ChangeEvent<{ value: unknown }>) => {
			let alertStatus = !evt.target.value ? undefined : evt.target.value as PatientListFilterAlertType
			onChangeFilter({ ...filter, alertStatus })
		},
		id: "alertFilter",
		fullWidth: false,
		displayEmpty: true,
	}

	return (
		<Box display="flex" mt={5} mb={3} alignItems="center">
			<Box mr={1}>Filter:</Box>
			<Box>
				<DataPointSelect {...dataPointsProps} />
			</Box>
			<Box width={"1rem"} />
			<Box>
				<Select {...alertProps}>
					<MenuItem value={""}>All</MenuItem>
					<MenuItem value={PatientListFilterAlertType.Alerts}>Alerts</MenuItem>
					<MenuItem value={PatientListFilterAlertType.NoAlerts}>No Alerts</MenuItem>
					<MenuItem value={PatientListFilterAlertType.NoThresholds}>No Thresholds</MenuItem>
				</Select>
			</Box>

		</Box>
	)
}

type SortOrderType = 'asc' | 'desc'
type SortQuery = { sortBy: PatientSortType, sortOrder: SortOrderType }

const usePatientListStyles = makeStyles(() => ({
	name: {
		maxWidth: "40rem",
		textOverflow: "ellipsis",
		position: "relative",
		overflow: "hidden",
		display: "inline-block"
	}
}))

const useLinearProgressStyles = makeStyles((theme) => ({
	root: {
		height: "1.8rem",
		background: theme.palette.grey[500],
		borderRadius: "0.6rem",
	}
}))

const useLinearProgressDisabledStyles = makeStyles((theme) => ({
	root: {
		height: "1.8rem",
		background: theme.palette.grey[300],
		borderRadius: "0.6rem",
	}
}))


const DataTracker: FC<{ daysOfData?: number, totalDays?: number }> = ({ daysOfData, totalDays }) => {
	//	daysOfData = Math.round(Math.random() * 30)
	const styles = useLinearProgressStyles()
	const disabledStyles = useLinearProgressDisabledStyles()
	let classes
	let value

	const maxDays = totalDays || 30
	if (daysOfData === undefined) {
		value = 0
		classes = disabledStyles
	} else {
		value = Math.min(100, (daysOfData / maxDays) * 100)
		classes = styles
	}

	return (
		<Box minWidth="15rem" px={0.5}>
			<Box width="100%" position="relative" height="1.8rem" style={{ fontSize: "1.2rem" }}>
				<Box position="absolute" left="-0.6rem">0</Box>
				{value > 0 && value < 100 && <Box position="absolute" ml={value > 9 ? "-1.2rem" : ""} pl={0.3} left={`${value}%`}>{daysOfData}</Box>}
				<Box position="absolute" right="-1.4rem">{maxDays}</Box>
			</Box>
			<Box>
				<LinearProgress classes={classes} color="primary" variant="determinate" value={value} />
			</Box>
		</Box>
	)
}

const PatientRow: FC<{ data: IPatientListItem, customInterval?: number }> = ({ data, customInterval }) => {
	const { daysOfData } = useGetIntervalDaysOfData(data.activeInterval?.id)

	const { id, email, watched, firstName, lastName, activeInterval, lastSync } = data
	const styles = usePatientListStyles()
	const [setPatientWatched] = useSetPatientWatched()

	const { intervalEnd, intervalStart } = activeInterval || {}

	let dpWithThreshold = activeInterval?.dataPointMetrics?.filter(dp => dp.hasThresholds)?.map(dp => dp.dataPointType) || []
	let dpWithAlerts = activeInterval?.dataPointMetrics?.filter(dp => dp.hasAlerts)?.map(dp => dp.dataPointType) || []
	const onWatch = () => {
		setPatientWatched(id, true)
	}

	const onUnwatch = () => {
		setPatientWatched(id, false)
	}

	let icnStyle = {
		cursor: "pointer" as "pointer"
	}

	let name = `${firstName} ${lastName}`.trim() || email

	let dateIntervalCol
	if (!!customInterval) {
		dateIntervalCol = (<>
			{intervalStart && <FormattedDate value={intervalStart} day="numeric" month="long" />}
	&nbsp;-&nbsp;
			{intervalEnd && <FormattedDate value={intervalEnd} day="numeric" month="long" year="numeric" />}
		</>)

	} else {
		dateIntervalCol = (intervalEnd && <FormattedDate value={intervalEnd - 86400000} month="long" year="numeric" />)
	}
	
	// add 1000 ms to account for the 1 second before midnight at which the prior interval ends
	const daysInInterval = !intervalEnd || !intervalStart || isNaN(intervalEnd) || isNaN(intervalStart) ? 30 : differenceInCalendarDays(intervalEnd + 1000, intervalStart)

	return (
		<TableRow key={id}>
			<TableCell align={'center'}>
				{watched ? <StarIcon style={icnStyle} onClick={onUnwatch} fontSize="inherit" /> : <StarBorderIcon style={icnStyle} onClick={onWatch} fontSize="inherit" />}
			</TableCell>
			<TableCell>
				<Link className={styles.name} component={RoutedLink} to={`/patients/${id}`}>
					{name}
				</Link>
			</TableCell>
			<TableCell>
				{dateIntervalCol}
			</TableCell>
			<TableCell>
				{intervalEnd && !isNaN(intervalEnd) && Math.max(0, differenceInCalendarDays(intervalEnd, Date.now()))}
			</TableCell>
			<TableCell>
				<DataTracker daysOfData={daysOfData} totalDays={daysInInterval} />
			</TableCell>
			<TableCell>
				<Box display="flex">
					<DataPointIconList thresholdedDataPoints={dpWithThreshold} alertDataPoints={dpWithAlerts} />
					<Box flex={1} />
				</Box>
			</TableCell>
			<TableCell>
				{lastSync && !isNaN(lastSync) && (formatDistanceStrict(lastSync, new Date()) + " ago")}
			</TableCell>
		</TableRow>
	)
}





const PatientList: FC = () => {
	const history = useHistory()
	const query = history.location.search

	const parseQuery = useCallback(() => {
		if (query) {
			let searchParms = new URLSearchParams(query)
			try {
				let filterRaw = searchParms.get("filter")
				let filter: IPatientListFilter
				if (!!filterRaw) {
					filter = JSON.parse(filterRaw) as IPatientListFilter
				} else {
					filter = {}
				}

				let sortRaw = searchParms.get("sort")
				let sort: SortQuery
				if (!!sortRaw) {
					sort = JSON.parse(sortRaw) as SortQuery
				} else {
					sort = {
						sortOrder: "asc",
						sortBy: PatientSortType.Name
					}
				}

				return {
					filter,
					sort,
				}
			} catch (error) {
				console.warn(error)
			}
		}
		return {
			filter: {},
			sort: {
				sortOrder: "asc",
				sortBy: PatientSortType.Name
			} as SortQuery
		}
	}, [query])

	const parsedQuery = parseQuery()
	const [sortOrder, setSortOrder,] = useState<SortOrderType>(parsedQuery.sort.sortOrder)
	const [sortBy, setSortBy,] = useState<PatientSortType>(parsedQuery.sort.sortBy)
	const [total, setTotal,] = useState<number>(-1)
	const [pageIndex, setPageIndex,] = useState<number>(0)
	const [rowsPerPage, setRowsPerPage,] = useState<number>(50)
	const [filter, setFilter] = useState<IPatientListFilter>(parsedQuery.filter)
	const patientListQuery = useGetPatientsList(pageIndex, rowsPerPage, sortBy, sortOrder === 'asc' ? SortOrder.Asc : SortOrder.Desc, filter)
	const practiceQuery = useGetPracticeInfo()

	const { patients: rawPatients, count, } = patientListQuery

	const loading = patientListQuery.loading || practiceQuery.loading
	const error = patientListQuery.error || practiceQuery.error

	useEffect(() => {
		if (_.isFinite(count)) {
			setTotal(count || 0)
		}
	}, [count,])

	useEffect(() => {
		let { filter, sort } = parseQuery()
		setFilter(filter)
		setSortOrder(sort.sortOrder)
		setSortBy(sort.sortBy)
	}, [parseQuery])


	const updateQueryLocation = (f: IPatientListFilter = filter, s: SortQuery = { sortBy, sortOrder }) => {
		let filterStr = encodeURIComponent(JSON.stringify(f))
		let sortStr = encodeURIComponent(JSON.stringify(s))
		history.replace(`/patients?filter=${filterStr}&sort=${sortStr}`)
	}


	const setSort = (val: PatientSortType) => {
		if (sortBy === val) {
			let newVal: SortOrderType = sortOrder === 'asc' ? 'desc' : 'asc'
			setSortOrder(newVal)
			updateQueryLocation(undefined, { sortBy, sortOrder: newVal })
		} else {
			setSortBy(val)
			updateQueryLocation(undefined, { sortBy: val, sortOrder })
		}
	}

	const updateSortFn = (type: PatientSortType) => {
		return () => {
			setSort(type)
		}
	}


	const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
		setPageIndex(page)
	}

	const onChangeRowsPerPage = (evt: ChangeEvent<HTMLInputElement>) => {
		let rpp = parseInt(evt.target.value, 10)
		setRowsPerPage(rpp)
		if (pageIndex > 0 && pageIndex * rpp > (count || 0)) {
			setPageIndex(0)
		}
	}

	const patients = loading ? [] : rawPatients || []

	const customInterval = practiceQuery.practice?.customInterval
	const rowEls = _.map(patients, (listEl) => {
		return <PatientRow key={listEl.id} data={listEl} customInterval={customInterval} />
	})
	const emptyRows = rowsPerPage - (patients || []).length

	const onUpdateFilter = (filter: IPatientListFilter) => {
/*		let filterStr = encodeURIComponent(JSON.stringify(filter))
		let sort = {
			sortBy, 
			sortOrder
		}
		let sortStr = encodeURIComponent(JSON.stringify(sort))
		history.replace(`/patients?filter=${filterStr}&sort=${sortStr}`)
*/		setFilter(filter)
		updateQueryLocation(filter)
	}

	return (
		<Box flex={1} display="flex" flexDirection="column">
			<PageBanner title="Patient List" />
			<Box px={10} pb={2} flex={1} position="relative" overflow="hidden" display="flex" flexDirection="column">
				<Filters filter={filter} onChangeFilter={onUpdateFilter} />
				{!!error && <ErrorBox my={2} message="Failed to load data" />}
				<Box flex={1} position="relative">
					<TableContainer style={{ position: "absolute", maxHeight: "100%", height: "100%", width: "100%" }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell align={'center'}>
										<TableSortLabel color="inherit" active={sortBy === PatientSortType.Watched} direction={sortOrder} onClick={updateSortFn(PatientSortType.Watched)}>
											Watch
										</TableSortLabel>
									</TableCell>
									<TableCell align={'left'}>
										<TableSortLabel color="inherit" active={sortBy === PatientSortType.Name} direction={sortOrder} onClick={updateSortFn(PatientSortType.Name)}>
											Name
										</TableSortLabel>
									</TableCell>
									<TableCell align={'left'}>
										<TableSortLabel color="inherit" active={sortBy === PatientSortType.IntervalEnd} direction={sortOrder} onClick={updateSortFn(PatientSortType.IntervalEnd)}>
											Interval
										</TableSortLabel>
									</TableCell>
									<TableCell align={'left'}>
										Days Left
									</TableCell>
									<TableCell align={'left'}>
										Data Tracker
									</TableCell>
									<TableCell align={'left'}>
										Data Points
									</TableCell>
									<TableCell align={'left'}>
										<TableSortLabel color="inherit" active={sortBy === PatientSortType.LastySync} direction={sortOrder} onClick={updateSortFn(PatientSortType.LastySync)}>
											Last Transmission
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rowEls}
								{emptyRows > 0 && (
									<TableRow>
										<TableCell colSpan={8} style={{ height: 53 * emptyRows, }}>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					{loading &&
						<Box position="absolute" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
							{loading && <CircularProgress />}
						</Box>
					}
				</Box>
				<TablePagination component="div" colSpan={8} count={total} rowsPerPageOptions={[50, 100,]} onChangeRowsPerPage={onChangeRowsPerPage} rowsPerPage={rowsPerPage} page={pageIndex} onChangePage={handleChangePage} />
			</Box>
		</Box>
	)
}

export default PatientList
