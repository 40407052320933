import React, { FC } from 'react'

import { Box, BoxProps, Card, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

type ErrorBoxProps = BoxProps & {message:string}

const ErroBox: FC<ErrorBoxProps> = (props) => {
	const p2 = {
		display:'flex',
		justifyContent:'center',
		alignItems:'center',
		...props,
	}
	delete p2.message
	return (
		<Box {...p2}>
			<Card raised style={{margin:'auto', padding:'1rem 2rem'}}>
				<Box display="flex" minHeight="4rem" justifyContent="center" alignItems="center">
					<WarningIcon color="error" fontSize="large" style={{marginRight:'0.5rem'}} />
					<Typography variant="body1">{props.message}</Typography>
				</Box>
			</Card>
		</Box>
	)
}

export default ErroBox
