import React, { FC } from 'react'

import { Box, Grid, makeStyles } from '@material-ui/core'

import PageBanner from 'components/PageBanner'
import AlertList from './AlertList'
import ReadyToSignList from './ReadyToSignList'

const useStyles = makeStyles((theme)=> ({
	column: {
		minHeight: "100%",
	},
	columnBorder: {
		minHeight: "100%",
		flex: 1,
		borderRight:`solid 0.1rem ${theme.palette.borderColor}`
	}
}))

const Dashoard: FC = () => {
	const styles = useStyles()
	return (
		<Box flex={1} display="flex" flexDirection="column" minHeight="100%">
			<PageBanner title="My Desk" />
			<Box pt={3} px={1} pb={3} flex={1} display="flex" flexDirection="column">
				<Grid container style={{flex:1}}>
					<Grid item xs>
						<Box className={styles.columnBorder} px={2} display="flex" flexDirection="column">
							<AlertList />
						</Box>
					</Grid>
					<Grid item xs>
						<Box className={styles.column} px={2} display="flex" flexDirection="column">
							<ReadyToSignList />
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default Dashoard
