import React, { FC, useState, MouseEvent, ChangeEvent, useEffect } from 'react'

import { isNumber } from 'underscore'
import { Box, TableContainer, Table, TableHead, TableRow, TableSortLabel, TableCell, TableBody, TablePagination, Typography, Button, Checkbox, CircularProgress } from '@material-ui/core'
import { FormattedDate } from 'react-intl'
import { Link as RoutedLink } from 'react-router-dom'

import { SortOrder, BillingSortBy, useGetBillingList, BillingFilter, useUpdatePatientInterval } from 'store'
import { ErrorBox } from 'components'

export type BillingListProps = {
	filter: BillingFilter
}

const BillingList: FC<BillingListProps> = ({ filter }) => {
	const [sortOrder, setSortOrder,] = useState<'asc' | 'desc'>('asc')
	const [sortBy, setSortBy,] = useState<BillingSortBy>(BillingSortBy.ReportNumber)
	const [total, setTotal,] = useState<number>(-1)

	const [pageIndex, setPageIndex,] = useState<number>(0)
	const [rowsPerPage, setRowsPerPage,] = useState<number>(10)

	const { results, loading, error, count } = useGetBillingList(filter, pageIndex, rowsPerPage, sortBy, sortOrder === 'asc' ? SortOrder.Asc : SortOrder.Desc)
	const [updateInterval] = useUpdatePatientInterval()

	useEffect(() => {
		if (isNumber(count)) {
			setTotal(count || 0)
		}
	}, [count,])
	
	const onChangeRowsPerPage = (rpp:number) => {
		setRowsPerPage(rpp)
		setPageIndex(0)
	}

	const setSort = (val: BillingSortBy) => {
		if (sortBy === val) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortBy(val)
		}
	}

	const updateSortFn = (type: BillingSortBy) => {
		return () => {
			setSort(type)
		}
	}

	const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
		setPageIndex(page)
	}

	const rowEls = results?.map((billingItem) => {

		const is99457 = billingItem.signoff.find(a => !!a.cpt_99457)
		const is99458 = billingItem.signoff.find(a => !!a.cpt_99458)
		const is99458b = billingItem.signoff.find(a => !!a.cpt_99458_2)
		const is99454 = billingItem.signoff.find(a => !!a.cpt_99454)
		const approvedBy = billingItem.signoff.find(a => !!a.approvedOn)
		const physician = billingItem.patient.physician
		const indication = billingItem.patient.indication
		
		let cptCodes = []
		if (is99457) {
			cptCodes.push("99457")
		}
		if (is99458) {
			cptCodes.push("99458")
		}
		if (is99458b) {
			cptCodes.push("99458")
		}
		if (is99454) {
			cptCodes.push("99454")
		}

		const onChageBilled = (evt: ChangeEvent<HTMLInputElement>) => {
			//TODO
			updateInterval(billingItem.id, { billed: evt.target.checked })
		}

		let approverName = ""
		let approvedOn

		if (approvedBy) {
			approverName = approvedBy.practitioner.name
			approvedOn = <FormattedDate value={approvedBy.approvedOn} day="numeric" month="numeric" year="numeric" />
		} else {
			approverName = "Pending Approval" //is99457.practitioner.name
			approvedOn = ""//"PENDING APPROVAL"
		}


		let patientNameEl = <Typography color="primary" variant="h3">{billingItem.patient.firstName} {billingItem.patient.lastName}</Typography>


		return (
			<TableRow key={billingItem.id} className="printSmallText">

				<TableCell align={'left'}>
					<Checkbox checked={billingItem.billed} onChange={onChageBilled} />
				</TableCell>

				<TableCell align={'left'}>
					<FormattedDate value={billingItem.intervalEnd} month="short" day="numeric" year="numeric" />
				</TableCell>

				<TableCell align={'left'}>
					{patientNameEl}
					<Typography variant="body2" component="div">
						DOB: {billingItem.patient.dob} MRN:{billingItem.patient.mrn}
					</Typography>
				</TableCell>

				<TableCell align={'left'}>
					{cptCodes.join(", ")}
				</TableCell>

				<TableCell align={'left'}>
					{approverName}
					<Box>
						<Typography variant="body2">{approvedOn}</Typography>
					</Box>
				</TableCell>

				<TableCell align={'left'}>
					{physician}
				</TableCell>
				
				<TableCell align={'left'}>
					{indication}
				</TableCell>

				<TableCell align={'left'}>
					{billingItem.daysOfData}
				</TableCell>

				<TableCell align={'right'}>
					<Button component={RoutedLink} to={`/patients/${billingItem.patient.id}/${billingItem.id}`} variant="contained" color="primary">View</Button>
				</TableCell>

			</TableRow>
		)
	})

	const emptyRows = rowsPerPage - (results || []).length


	return (
		<Box flex={1} display="flex" flexDirection="column">
			{!!error && <ErrorBox my={2} message="Failed to load data" />}
			<Box flex={1} position="relative" mx={2} my={3}>
				{loading && <Box display="flex" position="absolute" width="100%" height="100%" justifyContent="center" alignItems="center">
					<CircularProgress />
				</Box>}
				{!loading  && <TableContainer className={"printStatic"} style={{ position: "absolute", maxHeight: "100%", height: "100%", width: "100%" }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow className="printSmallText">
								<TableCell align={'left'}>
									<TableSortLabel color="inherit" active={sortBy === BillingSortBy.Billed} direction={sortOrder} onClick={updateSortFn(BillingSortBy.Billed)}>
										Billed
									</TableSortLabel>
								</TableCell>
								<TableCell align={'left'}>
									<TableSortLabel color="inherit" active={sortBy === BillingSortBy.IntervalEnd} direction={sortOrder} onClick={updateSortFn(BillingSortBy.IntervalEnd)}>
										End Date
									</TableSortLabel>
								</TableCell>
								<TableCell align={'left'}>
									<TableSortLabel color="inherit" active={sortBy === BillingSortBy.Patient} direction={sortOrder} onClick={updateSortFn(BillingSortBy.Patient)}>
										Patient
									</TableSortLabel>
								</TableCell>

								<TableCell align={'left'}>
									CPT
								</TableCell>
								<TableCell align={'left'}>
									Signed By &amp; On
								</TableCell>
								<TableCell align={'left'}>
									Physician
								</TableCell>
								<TableCell align={'left'}>
									Indication
								</TableCell>
								<TableCell align={'left'}>
									Days of Data
								</TableCell>
								<TableCell align={'left'}>
								</TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{rowEls}
							{emptyRows > 0 && (
								<TableRow>
									<TableCell colSpan={8} className={"printHidden"} style={{ height: 53 * emptyRows, }}>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>}
			</Box>
			<TablePagination component="div" className="printMarginTop2" colSpan={8} count={total} rowsPerPageOptions={[10, 25]} onChangeRowsPerPage={(evt: ChangeEvent<HTMLInputElement>) => onChangeRowsPerPage(parseInt(evt.target.value, 10))} rowsPerPage={rowsPerPage} page={pageIndex} onChangePage={handleChangePage} />
		</Box>

	)
}

export default BillingList