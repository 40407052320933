import React, { FC, useState, ChangeEvent, useEffect } from 'react'

import { Box, makeStyles, CircularProgress, IconButton, Select, MenuItem, Typography, Button } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'

import { format } from 'utils/date'
import { useGetEcgReading, useGetEcgReadings, PatientInterval, IPatientInfo, ID, useDismissAlert } from 'store'
//import EcgGraph from './EcgGraph'
import { EcgGraph, EcgGraphModal } from './EcgGraphModal'
import { getClassificationType } from 'utils'
import { EcgSymptomList } from 'components'

const useAlertStyles = makeStyles((theme) => ({
	headerBox: {
		padding: "3rem 2rem",
		borderLeft: `solid 1.6rem transparent`,
		borderBottom: `solid 0.1rem ${theme.palette.borderColor}`,
	},	
	cardBorderAlert: {
		borderLeftColor: theme.palette.alertRed,
	},
	cardRoot: {
		border: `solid 0.1rem ${theme.palette.borderColor}`,
		margin: "0.4rem",
		height: "55rem",
	},
  dismissAlert: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
		borderRadius:"999px",
  },

}))


type EcgCardProps = {
	patient: IPatientInfo,
	selectedInterval: PatientInterval

}


const EcgCard: FC<EcgCardProps> = ({ patient, selectedInterval }) => {
	const styles = useAlertStyles()
	const [dismissAlert, { loading:dismissingAlert, error:dismissError, called: afterAlertDismissed }] = useDismissAlert()
	const { loading: loadingList, readings } = useGetEcgReadings(patient.id, selectedInterval.id)
	const [selectedReading, setSelectedReading] = useState<ID | undefined>(undefined)
	const { loading: loadingData, ecgReading } = useGetEcgReading(patient.id, selectedReading)
	const [modalOpen, setModalOpen] = useState(false)
	useEffect(() => {
			setSelectedReading(undefined)
	}, [selectedInterval, patient])
	useEffect(() => {
		if (!!selectedReading) {
			return
		}
		if (readings) {
			setSelectedReading(readings[0]?.id)
		} else {
			setSelectedReading(undefined)
		}			
		
	}, [selectedReading, readings])

	const onSelectReading = (evt: ChangeEvent<{ value: unknown }>) => {
		setSelectedReading(evt.target.value as ID)
	}
	const onShowFullHistory = () => {
		setModalOpen(true)
	}
	const reading = readings?.find(r => r.id === selectedReading)
	const hasAlert = !!reading?.alert?.id
	const alertDismissed =  !!reading?.alert?.dismissed || (!dismissError && afterAlertDismissed)
	const menuItems = readings?.map(r => {
		const hasSymptoms = !!r?.symptoms?.length
		return <MenuItem key={r.id} value={r.id}>{format(new Date(r.time), "MM/dd/yyyy hh:mm a")} {getClassificationType(r.classification)} {hasSymptoms ? "*" : ""}</MenuItem>
	})

	const onDismissAlert = () =>{
		let alertId = reading?.alert?.id
		if (!!alertId) {
			dismissAlert(alertId)
		}
	}

	const loading = loadingList || loadingData
	const isEnabled = !loadingList && !!readings?.length
	return (
		<Box className={`${styles.cardRoot}`}>
			{!!ecgReading && <EcgGraphModal {...ecgReading} patient={patient} open={modalOpen} onClose={() => setModalOpen(false)} />}
			<Box display="flex" flexDirection="column" flex={1} height="100%">
				<Box className={`${styles.headerBox} ${hasAlert ? styles.cardBorderAlert : ""}`} pl={0.5} height={"3.5rem"} display="flex" justifyContent="center" alignItems="center">
					<Box color="text.primary">
						<Typography variant="h3">ECG's</Typography>
					</Box>
					<Box color="text.primary">
						<IconButton onClick={onShowFullHistory} disabled={!isEnabled} color="inherit">
							<LaunchIcon />
						</IconButton>
					</Box>
					<Box flex={1} />
					<Box>
						{isEnabled && <Select onChange={onSelectReading} value={selectedReading || ""}>
							{menuItems}
						</Select>}
					</Box>
				</Box>
				{!!ecgReading && <Box display="flex">
					<Box pl={2} py={1} flex={1}>
						<Box>
							<Typography variant="subtitle1">Pulse: {ecgReading.pulse}</Typography>
						</Box>
						<Box>
							<Typography variant="subtitle1">Classification: {getClassificationType(ecgReading.classification)}</Typography>
						</Box>
						{!!reading?.symptoms?.length && <Box>
							<Typography variant="subtitle1">Symptoms: <EcgSymptomList limited symptoms={reading?.symptoms} /></Typography>
						</Box>}
					</Box>
					{hasAlert && !alertDismissed && <Box m={2}>
						{dismissingAlert && <Box mr={1} display="inline-block"><CircularProgress size={14} /></Box>}
						<Button disabled={dismissingAlert} onClick={onDismissAlert} variant="contained" className={styles.dismissAlert}>Dismiss</Button>
					</Box>}
				</Box>}
				<Box flex={1} position="relative">
					<Box position="absolute" top={0} left={0} right={0} bottom={0}>
						{loading && <Box display="flex" height="100%" justifyContent="center" alignItems="center">
							<CircularProgress />
						</Box>}
						{!loading && !isEnabled && <Box display="flex" height="100%" justifyContent="center" alignItems="center">
							<Typography variant="h4">No Readings</Typography>
						</Box>}
						{!!ecgReading && <EcgGraph time={ecgReading.time} key={selectedReading} data={ecgReading.data || []} />}
					</Box>
				</Box>
				<Box pb={1} />
			</Box>
		</Box>
	)
}

export default EcgCard