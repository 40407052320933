import React, { FC } from 'react'

import { Box, FormControlLabel, Checkbox, makeStyles, Typography } from '@material-ui/core'
import { useParams } from 'react-router'

import { useGetPatientInfo } from 'store'
import { format } from 'utils/date'


const useStyles = makeStyles((theme) => ({
	root: {
		borderTop: `solid 0.4rem ${theme.palette.primary.main}`,
	}
}))

const PrintSignoff: FC = () => {
	const styles = useStyles()
	const { patientId, intervalId } = useParams<{ patientId: string, intervalId: string }>()
	const { patient } = useGetPatientInfo(patientId)
	if (intervalId?.toLowerCase() === "history") {
		return <></>
	}
	let selectedInterval = patient?.intervals.find(i => i.id === intervalId) || patient?.intervals[0]

	let is99457 = selectedInterval?.signoff.find(a => !!a.cpt_99457)
	let is99458 = selectedInterval?.signoff.find(a => !!a.cpt_99458)
	let is99458b = selectedInterval?.signoff.find(a => !!a.cpt_99458_2)
	let is99454 = selectedInterval?.signoff.find(a => !!a.cpt_99454)
	let approval = selectedInterval?.signoff.find(a => !!a.approvedOn)

	return (
		<Box className="printOnlyBlock">
			<Box px={2} pt={1} pb={1} display="flex" className={styles.root}>
				<Box flex={2}>
					<Box>
						<FormControlLabel
							control={<Checkbox size="medium" checked={!!is99457} name="99457" />}
							label="I acknowledge that I have read for at least 20 minutes."
						/>
					</Box>
					<Box>
						<FormControlLabel
							control={<Checkbox size="medium" checked={!!is99458} name="99458" />}
							label="I acknowledge that I have read for an additional 20 minutes."
						/>
						<FormControlLabel
							control={<Checkbox size="medium" checked={!!is99458b} name="99458_2" />}
							label="Second additional 20 minutes."
						/>
					</Box>
					<Box>
						<FormControlLabel
							control={<Checkbox size="medium" checked={!!is99454} name="99454" />}
							label="Device supplied by clinic. Received at least 16 days of data."
						/>
					</Box>
				</Box>
				<Box  mx={2}>
					<Box>
						<Typography variant="h4">
							Report reviewied and signed by:
						</Typography>
					</Box>
					<Box>
						<Typography variant="h3">
							{approval?.practitioner.name}
						</Typography>
					</Box>
				</Box>
				<Box mx={2}>
					<Box>
						<Typography variant="h4">
							Signed on:
						</Typography>
					</Box>
					<Box>
						<Typography variant="h3">
							{approval?.approvedOn && <Box>{format(new Date(approval.approvedOn || 0), "P p")}</Box>}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default PrintSignoff