import { ApolloError, useQuery, useMutation } from '@apollo/client'

import gql from 'graphql-tag'

import { DataPointType, DateTime, ID } from './types'
import { ExecutionResult } from 'graphql'
import { onError, update } from './utils'

const DATA_IN_RANGE = gql`
query DataPointsInRange($patientId:ID!, $intervalId: ID, $dataPointType:DataPointType!, $startTime:DateTime!, $endTime:DateTime!) {
	patient(id:$patientId) {
		id
		timezone
	}
	dataPoints (patientId:$patientId, intervalId:$intervalId, dataPointType:$dataPointType, startTime:$startTime, endTime:$endTime) {
		id
		time
		value
	}
}`

export const useGetDataPointsInRange = (patientId: ID, intervalId: ID | undefined, dataPointType: DataPointType, startTime: DateTime, endTime: DateTime): { loading: boolean; error?: ApolloError; dataPoints?: Array<DataPoint>; } => {
	const gqlQuery = DATA_IN_RANGE
	const variables = {
		patientId,
		intervalId,
		dataPointType,
		startTime,
		endTime,
	}
	const q = useQuery<{ dataPoints: Array<DataPoint>, patient:{id:ID, timezone:string} }>(gqlQuery, { variables })

	const patientTz = q.data?.patient?.timezone
	const dataPoints = q.data?.dataPoints
	return {
		...q,
		dataPoints: patientTz && dataPoints ? fixupDataPointsForTzDiff(dataPointType, dataPoints, patientTz) : dataPoints
	}
}



const GET_PATIENT_GRAPH_DATA = gql`
query PatientGraphData($patientId:ID!, $intervalId:ID, $dataPointType:DataPointType!, $startTime:DateTime, $endTime:DateTime, $alertsDismissed:Boolean) {
	dataPoints(patientId: $patientId, intervalId:$intervalId, dataPointType:$dataPointType, startTime:$startTime, endTime:$endTime) {
		id
		time
		value
	}	
	patient(id:$patientId) {
		id
		timezone
		thresholds(dataPointType:$dataPointType) {
			id
			min
			max
			createdOn
		}
	}
	alerts(rowsPerPage:9999, sortOrder:Desc, sortBy:StartedOn, filter:{dismissed:$alertsDismissed, patientId:$patientId, dataPoints:[$dataPointType]}) {
		count 
		results {
			id
			startedOn
			endedOn
			dismissed
			alertValue
			markers {
				id
				time
				value
			}
			dataPointType
		}
	}
}`


export type DataPoint = {
	time: DateTime
	value?: number
}

type GraphThreshold = {
	id: ID
	min?: number
	max?: number
	createdOn: DateTime
}

type RawGraphDataResult = Array<DataPoint>

type RawGraphDataThresholdResult = {
	id: ID
	timezone: string
	thresholds: Array<GraphThreshold>
}

type GraphDataAlert = {
	id: ID
	startedOn: DateTime
	endedOn?: DateTime
	dismissed: boolean
	alertValue: number
	markers: Array<{
		id: ID
		time: DateTime
		value: number
	}>
	dataPointType: DataPointType
}

type RawGraphDataAlertsResult = {
	count: number
	results: Array<GraphDataAlert>
}


export type PatientGraphData = {
	dataPoints: Array<DataPoint>
	thresholds: Array<GraphThreshold>
	alerts: Array<GraphDataAlert>
}

const getRelativeTimezoneOffset = (timeZone: string) => {
	const d = new Date(new Date().toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric" }))
	const s = new Date(d.toLocaleString('en-US', { timeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric" }))
	return d.getTime() - s.getTime()
}

const fixupDataPointsForTzDiff = (dataPoint: DataPointType, dataPoints: Array<DataPoint>, patientTz?: string): Array<DataPoint> => {
	if ((dataPoint !== DataPointType.Sleep && dataPoint !== DataPointType.StepCounter) || !patientTz || !dataPoints) {
		return dataPoints
	}
	const relativeOffset = getRelativeTimezoneOffset(patientTz || "America/Los_Angeles")
	if (relativeOffset === 0) {
		return dataPoints
	}
//	console.log('RELATIVE OFFSET', relativeOffset)
	return dataPoints.map(d => ({
		...d,
		time: d.time - relativeOffset
	}))
}

const DAY_MS = 86400000
const daysAgo_31 = Date.now() - DAY_MS * 31

export interface PatientGraphDataFilter { startTime?: DateTime, endTime?: DateTime, includeDismissedAlerts?: boolean }
export const useGetPatientGraphData = (patientId: ID, intervalId: ID | undefined, dataPointType: DataPointType | undefined, filter: PatientGraphDataFilter): { loading: boolean; error?: ApolloError; graphData?: PatientGraphData; } => {
	const gqlQuery = GET_PATIENT_GRAPH_DATA
		
	let alertsDismissed: boolean | undefined = false
	if (filter.includeDismissedAlerts !== undefined) {
		if (filter.includeDismissedAlerts) {
			alertsDismissed = undefined
		} else {
			alertsDismissed = false
		}
	}
	
	if (!filter.startTime && !filter.endTime) {
		filter.startTime = daysAgo_31
	} else if (!filter.startTime && filter.endTime) {
		filter.startTime = filter.endTime - DAY_MS * 60
	} else if (!filter.endTime && filter.startTime) {
		filter.endTime = filter.startTime + DAY_MS * 60
	}

	const variables = {
		patientId,
		intervalId,
		dataPointType,
		...filter,
		alertsDismissed,
	}
	
	const q = useQuery<{ dataPoints: RawGraphDataResult, patient: RawGraphDataThresholdResult, alerts: RawGraphDataAlertsResult }>(gqlQuery, { variables, skip: !dataPointType })

	let graphData
	if (!!dataPointType && q.data?.patient && q.data?.dataPoints && q.data?.alerts) {
		graphData = {
			dataPoints: fixupDataPointsForTzDiff(dataPointType, q.data.dataPoints, q.data.patient.timezone),
			thresholds: q.data?.patient.thresholds,
			alerts: q.data?.alerts.results,
		}
	}
	return {
		...q,
		graphData,
	}
}


const UPDATE_PATIENT_THRESHOLD = gql`
mutation SetThreshold($patientId:ID!, $dataPointType:DataPointType!, $min:Int, $max:Int) {
	setThreshold(patientId:$patientId, dataPointType:$dataPointType, min:$min, max:$max) {
		id
		thresholds {
			id
			dataPointType
			min
			max			
		}
	}
}`


type UpdatePatientThresholdMutation = [(min?: number, max?: number) => Promise<ExecutionResult>, { loading: boolean; error?: Error; called: boolean }]
export const useSetPatientThreshold = (patientId: string, dataPointType: DataPointType): UpdatePatientThresholdMutation => {
	const gqlQuery = UPDATE_PATIENT_THRESHOLD

	const mut = useMutation<{ setPatientWatched: { id: string } }>(gqlQuery, { update, onError })
	const fn = (min?: number, max?: number): Promise<ExecutionResult> => {
		const variables = { patientId, dataPointType, min, max }
		return mut[0]({ variables, refetchQueries: (() => ['PatientGraphData', 'GetPatientListQuery']) })
	}
	return [fn, mut[1]]
}


