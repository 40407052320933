import React, { FC, ChangeEvent, useState } from 'react'
import _, { difference, without } from 'underscore'
import { MenuItem, Select, SelectProps } from '@material-ui/core'


import { DataPointType } from 'store'

export type DataPointSelectProps = {
	multiple?: boolean
	value?: Array<DataPointType> | DataPointType
	onChange?: (value: Array<DataPointType> | DataPointType) => void
} & Omit<SelectProps, 'onChange'>
const DataPointSelect: FC<DataPointSelectProps> = (props) => {
	let [open, setOpen] = useState(false)
	let { onChange, value, multiple } = props
	let dataPoints: DataPointType | Array<DataPointType> | "" = value || (multiple ? [] : "")

	const allPoints = [DataPointType.Pulse, DataPointType.Sleep, DataPointType.StepCounter, DataPointType.O2]

	const isAllSelected = !props.value?.length || !difference(allPoints, props.value || []).length
	const dataPointsProps = {
		...props,
		open,
		onOpen: () => {
			setOpen(true)
		},
		onClose: () => {
			setOpen(false)
		},
		MenuProps: multiple ? {
			//@ts-ignore
			getContentAnchorEl: ():Element => undefined,
			MenuListProps: {
				onMouseLeave: () => {
					setOpen(false)
				}
			}
		} : undefined,
		value: isAllSelected ? [""] : dataPoints,
		onChange: (evt: ChangeEvent<{ value: unknown }>) => {
			if (!onChange) {
				return
			}
			if (multiple) {
				if (isAllSelected) {
					let dataPoints = evt.target.value as Array<string>
					onChange(without(dataPoints, "") as Array<DataPointType>)
				} else {
					if ((evt.target.value as Array<string>).indexOf("") > -1) {
						onChange([]) // empty is "all"
					} else {
						let dataPoints = evt.target.value as Array<DataPointType>
						onChange(dataPoints)
					}

				}
			} else {
				let dataPoint = evt.target.value as DataPointType
				onChange(dataPoint)
			}
		},
		renderValue: multiple ? () => {
			if (dataPoints.length === 0 || dataPoints.length === _.keys(DataPointType).length) {
				return (props.label ? props.label + " " : "") + "All"
			}
			//TODO abstract to helper
			return _.map(dataPoints, (dp) => {
				switch (dp) {
					case DataPointType.BloodPressure:
						return "Blood Pressure"
					case DataPointType.O2:
						return "O2"
					case DataPointType.Pulse:
						return "Heart Rate"
					case DataPointType.Sleep:
						return "Sleep"
					case DataPointType.StepCounter:
						return "Activity"
					case DataPointType.Weight:
						return "Weight"
					case DataPointType.Systolic:
						return "Blood Pressure"
					case DataPointType.Ecg:
						return "Ecg"
					default:
						return "?"
				}
			}).join(", ")

		} : undefined
	}

	return (
		<Select {...dataPointsProps}>
			<MenuItem value={""}>All</MenuItem>
			<MenuItem value={DataPointType.Pulse}>Heart Rate</MenuItem>
			<MenuItem value={DataPointType.StepCounter}>Activity</MenuItem>
			<MenuItem value={DataPointType.Sleep}>Sleep</MenuItem>
			<MenuItem value={DataPointType.O2}>O2</MenuItem>
			<MenuItem value={DataPointType.Weight}>Weight</MenuItem>
			<MenuItem value={DataPointType.Systolic}>Blood Pressure</MenuItem>
			<MenuItem value={DataPointType.Ecg}>Ecg</MenuItem>
			{/*<MenuItem value={DataPointType.BloodPressure}>Blood Pressure</MenuItem>*/}
		</Select>
	)
}


export default DataPointSelect
