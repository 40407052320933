import React, { FC } from 'react'

import { Box } from '@material-ui/core'
import {AmplifyAuthenticator} from "@aws-amplify/ui-react"


const LoginView: FC = () => {
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<AmplifyAuthenticator />
		</Box>
	)
}

export default LoginView
