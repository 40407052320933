import React, { FC, useState } from 'react'

import { Input, InputProps } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'


interface TextMaskCustomProps {
	inputRef: (ref: HTMLInputElement | null) => void;
	showMask?: boolean
}

const TextMaskCustom: FC<TextMaskCustomProps> = (props) => {
	const { inputRef, showMask, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref: any) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={emailMask}
			placeholderChar={'\u2000'}
			showMask={showMask}
		/>
	);
}



const EmailTextField: FC<InputProps> = (props) => {
	let [showMask, setShowMask] = useState<boolean | undefined>(undefined)
	return (
		<Input onFocus={() => setShowMask(true)} onBlur={() => setShowMask(false)} {...props} inputProps={{ showMask }} inputComponent={TextMaskCustom as any} />
	)
}

export default EmailTextField
