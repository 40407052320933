import { useMutation, useQuery, ApolloError } from '@apollo/client'

import gql from 'graphql-tag'

import { ExecutionResult } from 'graphql'
import { DateTime, ID } from './types'

import { onError, update } from './utils'

const UPDATE_PATIENT_INTERVAL = gql`
mutation SetPatientWatched($intervalId:ID!, $updates:PatientIntervalUpdateInput!) {
	updatePatientInterval(intervalId:$intervalId, updates:$updates) {
		id
		intervalStart
		intervalEnd
		reportNote
		internalNote
		billed
	}
}`

interface UpdatePatientIntervalDto {
	intervalStart?: DateTime
	intervalEnd?: DateTime
	reportNote?: string
	internalNote?: string
	billed?: boolean
}

type UpdatePatientIntervalMutation = [(intervalId: string, updates: UpdatePatientIntervalDto) => Promise<ExecutionResult>, { loading: boolean; error?: Error; }]
export const useUpdatePatientInterval = (): UpdatePatientIntervalMutation => {
	const gqlQuery = UPDATE_PATIENT_INTERVAL

	const mut = useMutation<{ updatePatientInterval: { id: string } }>(gqlQuery, { onError, update })
	const fn = (intervalId: string, updates: UpdatePatientIntervalDto): Promise<ExecutionResult> => {
		const variables = { intervalId, updates }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}


export type PatientIntervalSignoffInput = {
	approved?: boolean
	cpt_99457?: boolean
	cpt_99458?: boolean
	cpt_99458_2?: boolean
	cpt_99454?: boolean
}

const SIGNOFF_PATIENT_INTERVAL = gql`
mutation SignoffInterval($intervalId:ID!, $data:PatientIntervalSignoffInput!) {
	signoffInterval(intervalId:$intervalId, data:$data) {
		id
		signoff {
			practitioner {
				id
				name
			}
			approvedOn
			cpt_99457
			cpt_99458
			cpt_99458_2
			cpt_99454
		}
		
	}
}`

interface UpdatePatientIntervalDto {
	intervalStart?: DateTime
	intervalEnd?: DateTime
	reportNote?: string
	internalNote?: string
}

type SignoffIntervalMutation = [(data: PatientIntervalSignoffInput) => Promise<ExecutionResult>, { loading: boolean; error?: Error; called: boolean; }]
export const useSignoffInterval = (intervalId: string): SignoffIntervalMutation => {
	const gqlQuery = SIGNOFF_PATIENT_INTERVAL

	const mut = useMutation<{ updatePatientInterval: { id: string } }>(gqlQuery, { onError })
	const fn = (data: PatientIntervalSignoffInput): Promise<ExecutionResult> => {
		const variables = { intervalId, data }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}

const DAYS_OF_DATA = gql`
query GetDaysOfData($intervalId: ID!) {
  interval (intervalId:$intervalId) {
    id
    daysOfData
  }
}`

export const useGetIntervalDaysOfData = (intervalId?:ID): { loading: boolean; error?: ApolloError; daysOfData?: number; } => {
  const gqlQuery = DAYS_OF_DATA
  const variables = {
    intervalId,
  }
  const q = useQuery<{ interval: {id:ID, daysOfData:number} }>(gqlQuery, { skip:!intervalId, variables })
  return {
    ...q,
    daysOfData: q.data?.interval?.daysOfData,
  }
}


