import React, { FC, useEffect, useState } from 'react'

import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

type SnackarErrorProps = {
	open: boolean
	message: string
	severity?: 'success' | 'info' | 'warning' | 'error'
}

const SnackbarMessage: FC<SnackarErrorProps> = (props) => {
	let { message, open:propsOpen, severity="info" } = props
	let [open, setOpen] = useState<Boolean>(false)
	
	useEffect(() => {
		setOpen(propsOpen)
	}, [propsOpen])
	
	const onClose = (): void => {
		setOpen(false)
	}
	
	return (
		<Snackbar
			onClose={onClose}
			autoHideDuration={6000}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			open={Boolean(open)}
		>
			<MuiAlert elevation={6} variant="filled" onClose={onClose} severity={severity}>{message}</MuiAlert>
		</Snackbar>
	)
}

export default SnackbarMessage
