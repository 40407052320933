import React, { FC, useState, ChangeEvent, useEffect } from 'react'

import { Box, Typography, makeStyles, Button, TextField, CircularProgress } from '@material-ui/core'

import { PatientInterval, useUpdatePatientInterval } from 'store'
import { SnackbarError } from 'components'


const useSidebarStyles = makeStyles((theme) => ({
	borderedContainer: {
		flex: 1,
		padding: "0.2rem 2.8rem 0.2rem 1.4rem",
		display: "flex",
		flexDirection: "column",
		borderLeft: `solid 0.1rem ${theme.palette.borderColor}`,
	},
	borderedBox: {
		marginBottom: "0.2rem",
		paddingTop: "0.1rem",
		borderBottom: `solid 0.2rem ${theme.palette.borderColor}`,
	}
}))


const NoteField:FC<{ selectedInterval?:PatientInterval, field:"reportNote" | "internalNote", rows:string }> = ({ field, rows, selectedInterval }) => {
	const styles = useSidebarStyles()
	const [formState, setFormState] = useState<string>()
	const [updateInterval, mutationStatus] = useUpdatePatientInterval()
	const savedVal = selectedInterval?.[field] || ""
	let {loading, error} = mutationStatus
	loading = loading || !selectedInterval
	const unchanged = formState === savedVal
	useEffect(()=> {
		setFormState(savedVal)
	}, [savedVal])
	
	const maxLenTf = (len: number) => {
		return { inputProps: { maxLength: len } }
	}
	
	const onChange = (evt:ChangeEvent<{value:unknown}>):void => {
		setFormState(evt.target.value as string)
	}
	
	const onSave = ():void => {
		updateInterval(selectedInterval?.id || "", {[field]:formState})	
	}
	
	const NoteRequired = () => {
		return (field === "reportNote") ? null : <Typography display={'inline'} variant="body2">(required for approval)</Typography>
	}

	let title = ""
	if (field === "reportNote") {
		title = "Patient Report Note:"
	} else if (field === "internalNote") {
		title = "Internal Patient Note: "
	}
	
	return (
		<Box className={styles.borderedBox}>
			<SnackbarError message={`Failed to update '${title}'`} error={error} />
			<Typography variant="h5">{title}<NoteRequired/></Typography>
			<Box my={1}>
				<TextField disabled={loading} fullWidth variant="outlined" rows={parseInt(rows)} {...maxLenTf(2000)} multiline value={formState} onChange={onChange} />
			</Box>
			<Box textAlign="right" mb={1}>
				{mutationStatus.loading && <CircularProgress style={{position:"relative", top:"0.5rem", marginRight:"2rem"}} size={18} />}
				<Button disabled={loading || unchanged} onClick={onSave} variant="contained" color="primary">Save</Button>
			</Box>
		</Box>
	)	
}


const IntervalNotesSidebar: FC<{ selectedInterval?:PatientInterval }> = ({ selectedInterval }) => {
	const styles = useSidebarStyles()


	return (
		<Box width="100%" minHeight="100%" display="flex" flexDirection="column">
			<Box className={styles.borderedContainer}>
				<NoteField selectedInterval={selectedInterval} field="internalNote" rows="18" />
				<NoteField selectedInterval={selectedInterval} field="reportNote" rows="8" />
			</Box>
		</Box>
	)
}

export default IntervalNotesSidebar