import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { onError } from "@apollo/link-error"

const errorLink = onError(({ graphQLErrors, networkError }) => {
	let authExpired = !!graphQLErrors?.find(e => e.message === "Unauthorized") 
	if (authExpired) {
		document.location.reload()
	}
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);
	if (networkError) console.log(`[Network error]: ${networkError}`);
})

const httpLink = createHttpLink({
	uri: '/graphql',
})

let jwtToken: string | undefined

export const setJwtToken = (jwt?: string): void => {
	jwtToken = jwt
}

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
		},
	}
})

const client = new ApolloClient({
	link: authLink.concat(errorLink).concat(httpLink),
	cache: new InMemoryCache(
	),
})

export default client