import React, { FC, } from 'react'

import { AppBar, Box, Toolbar, Button, Typography, } from '@material-ui/core'
import { Link as RoutedLink, Route, Switch, useLocation, useParams, } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { Auth } from 'aws-amplify'

import logo from './logo.png'
import { ID, useGetEcgReadings, useGetPatientInfo } from 'store'
import { format } from 'utils/date'


const OnboardingButton: FC = () => {
	return (
		<Switch>
			<Route path="/patients/new" render={() => <></>} />
			<Route render={() => (
				<Box ml={2} mr={3} className="printHidden">
					<Button variant="contained" color="primary" component={RoutedLink} to={`/patients/new`} startIcon={<AddIcon />}>add patient</Button>
				</Box>
			)} />
		</Switch>

	)
}

const PrintPatientMonitoringPeriod: FC = () => {
	const { patientId, intervalId } = useParams<{ patientId: ID, intervalId: string }>()
	const { patient } = useGetPatientInfo(patientId)
	const { readings } = useGetEcgReadings(patientId, intervalId)

	let selectedInterval = patient?.intervals.find(i => i.id === intervalId) || patient?.intervals[0]

	let is99457 = selectedInterval?.signoff.find(a => !!a.cpt_99457)
	let is99458 = selectedInterval?.signoff.find(a => !!a.cpt_99458)
	let is99458b = selectedInterval?.signoff.find(a => !!a.cpt_99458_2)
	let is99454 = selectedInterval?.signoff.find(a => !!a.cpt_99454)
	//let approval = selectedInterval?.signoff.find(a => !!a.approvedOn)

	const cpts = []
	if (is99457) {
		cpts.push("99457")
	}
	if (is99458) {
		cpts.push("99458")
	}
	if (is99458b) {
		cpts.push("99458")
	}
	if (is99454) {
		cpts.push("99454")
	}
	return (
		<Box className="printOnlyBlock" textAlign="right">
			<Box>
				<Typography variant="h5">
					Monitoring Month:
				</Typography>
				<Typography variant="h2" color="primary">
					{selectedInterval?.intervalEnd ? format(selectedInterval?.intervalEnd - 86400000, "MMMM yyyy") : ""}
				</Typography>
			</Box>
			<Box color="#959292" fontWeight="bold">
				<Box>
					Days of Data: {selectedInterval?.daysOfData}
				</Box>
				<Box>
					ECGs sent: {readings?.length}
				</Box>
			</Box>
			<Box fontWeight="bold">
				<Box display="flex">
					<Box flex={1}>
						<Typography variant="inherit" color="primary">
							CPT:&nbsp; {cpts.join(", ")}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)

}

const PrintPatientName: FC = () => {
	const { patientId } = useParams<{ patientId: ID }>()
	const { patient } = useGetPatientInfo(patientId)

	return (
		<Box pl={1} pt={1}>
			<Typography variant="h1" className="patientName">{patient?.firstName} {patient?.lastName}</Typography>
		</Box>)
}


const Header: FC = () => {
	
	const onLogout = () => {
		Auth.signOut()
		document.location.reload()
	}

	const route = useLocation()
	const isBilling = route.pathname.toLowerCase().indexOf("/billing") > -1
	
	return (
		<>
		{isBilling && <Box className={"printOnlyBlock"} ml={2} mt={2}>
					<img style={{ height: "7rem" }} src={logo} alt="Murj RPM" />
					<Route path="/patients/:patientId" component={PrintPatientName} />
				</Box>
		}
		<AppBar elevation={0} position="fixed" color="default" className={isBilling ? "billing" : ""}>
			<Toolbar>
				<Box className={"printOnlyBlock"} >
					<img style={{ height: "7rem" }} src={logo} alt="Murj RPM" />
					<Route path="/patients/:patientId" component={PrintPatientName} />
				</Box>

				<RoutedLink style={{ height: "7rem", marginTop: "1rem" }} to={'/'}>
					<img style={{ height: "7rem" }} src={logo} alt="Murj RPM" />
				</RoutedLink>
				
				<Box flex={1}>
					
				</Box>

				<Switch>
					<Route path="/patients/:patientId/history" render={() => <></>} />
					<Route path="/patients/:patientId/:intervalId" component={PrintPatientMonitoringPeriod} />
				</Switch>
				<Route path="/patients/" component={OnboardingButton} />
				<Button color="primary" onClick={() => onLogout()}>Log out</Button>
			</Toolbar>
		</AppBar>
		</>
	)
}

export default Header