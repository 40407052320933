import React, { FC, useEffect, useState } from 'react'

import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

type SnackarErrorProps = {
	error?: any
	message: string
}

const SnackarError: FC<SnackarErrorProps> = (props) => {
	let { message, error } = props
	let [open, setOpen] = useState<Boolean>(false)
	
	useEffect(() => {
		if (error) {
			setOpen(true)
		}
	}, [error])
	
	const onClose = (): void => {
		setOpen(false)
	}
	
	return (
		<Snackbar
			onClose={onClose}
			autoHideDuration={6000}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			open={Boolean(open)}
		>
			<MuiAlert elevation={6} variant="filled" onClose={onClose} severity="error">{message}</MuiAlert>
		</Snackbar>
	)
}

export default SnackarError
