import React, { FC } from 'react'

import { Box, BoxProps, CircularProgress } from '@material-ui/core'

type CenteredLoaderProps = BoxProps & {loading:boolean}

const CenteredLoader: FC<CenteredLoaderProps> = (props) => {
	if (!props.loading) {
		return <></>
	}
	const p2 = {
		display:'flex',
		justifyContent:'center',
		alignItems:'center',
		...props,
	}
	delete p2.loading
	
	return (
		<Box {...p2}>
			<CircularProgress size={40} thickness={6} />
		</Box>
	)
}

export default CenteredLoader
